import {useResourceTypes} from "../../../Apis/Admin/ResourceTypeAdminApi";
import FlexRow from "../../../Components/FlexRow";
import {StyledText} from "../../../Components/Text";
import Table from "../../../Components/Table";
import Box from "../../../Components/Box";
import {useTranslation} from "react-i18next";
import Button from "../../../Components/Button";
import React, {useState} from "react";
import Gap from 'Components/Gap';
import {useAgreements} from "../../../Apis/Admin/AgreementAdminApi";
import {useDeleteResource, useResources, useSaveResource} from "../../../Apis/Admin/ResourcesAdminApi";
import {ResourceDto} from "../../../Apis/Models/ResourceDto";
import {Controller, useForm} from "react-hook-form";
import Modal from "../../../Components/Modal";
import Space from "../../../Components/Space";
import InputLabel from "../../../Components/InputLabel";
import FileUploadButton from "../../../Components/FileUploadButton";
import DropdownItem from "../../../Components/DropdownItem";
import Dropdown from "../../../Components/Dropdown";
import CheckmarkCircle from "../../../Assets/Icons/CheckmarkCircle";
import ReportIcon from "../../../Assets/Icons/V2/ReportIcon";
import AgreementSelector from "../../../Features/Dashboard/Components/AgreementSelector";
import The0MissionIcon from "../../../Assets/Icons/The0MissionIcon";
import useSelectedAgreement from "../../../Features/Dashboard/Hooks/useSelectedAgreement";
import useToast from "Hooks/useToast";
import { useNavigate } from "react-router-dom";

const ResourcesPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const selectedAgreement = useSelectedAgreement();

    const {data: resourceTypes, isLoading: isLoadingResourceTypes} = useResourceTypes();
    const {data: agreements, isLoading: isLoadingAgreements} = useAgreements()
    const {data: resources, isLoading: isLoadingResources} = useResources(selectedAgreement);

    const [mutateModalVisible, setMutateModalVisible] = useState<boolean>(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

    const { showErrorToast } = useToast();

    const {handleSubmit, register, control, reset, watch, setValue} = useForm<ResourceDto>();

    const {mutateAsync: saveResourceMutation, isLoading: isSavingResource} = useSaveResource();
    const {mutateAsync: deleteResourceMutation, isLoading: isDeletingResource} = useDeleteResource();

    const closeModal = () => {
        setMutateModalVisible(false);
        setDeleteModalVisible(false);
        reset({});
    }

    const selectResource = (resource: ResourceDto) => {
        reset(resource);
        setMutateModalVisible(true);
    }

    const saveResource = async (data: ResourceDto) => {
        const resourceType = resourceTypes?.find(x => x.id === data.resourceTypeId)!;

        if (!!resourceType.link && !data.resourceFile) {
            showErrorToast(t('resourceFileRequired'));
        }

        await saveResourceMutation(data);

        closeModal();
    }

    const deleteResource = async (resource: ResourceDto) => {
        reset(resource);
        setDeleteModalVisible(true)
    }

    const confirmDeleteResource = async (data: ResourceDto) => {
        await deleteResourceMutation(data);

        closeModal();
    }

    return (
        <Box>
            <Gap size={"medium"} block direction={"vertical"}>
                <FlexRow justify={"space-between"}>
                    <Gap size={"medium"}>
                        <StyledText type={"Lead"} weight={"Bold"} color={"secondary"}>{t('resources.title')}</StyledText>
                        <Button text={t('showResourceTypes')}  color={"link"} onClick={() => navigate('/admin/resourceTypes')} />
                    </Gap>

                    <Button color={"link"} onClick={() => setMutateModalVisible(true)} text={`+ ${t('createResource')}`}/>
                </FlexRow>
                <FlexRow justify={"space-between"}>
                    <AgreementSelector/>
                </FlexRow>
            </Gap>

            {(isLoadingResourceTypes || isLoadingAgreements || isLoadingResources) ? (
                <FlexRow align={"center"} justify={"center"}><The0MissionIcon spin width={50} height={50}></The0MissionIcon></FlexRow>
            ) : (
                <>
                    <Table>
                        <thead>
                        <tr>
                            <th>
                            </th>
                            <th>
                                {t('title')}
                            </th>
                            <th>
                                {t('description')}
                            </th>
                            <th>
                                {t('preview')}
                            </th>
                            <th>

                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {resources?.map((resource, index) => {
                            let resourceType = resourceTypes?.find(x => x.id === resource.resourceTypeId)!;

                            return (
                                <tr key={resource.id}>
                                    <td>
                                        {!!resourceType.icon && (
                                            <img src={resourceType.icon.url} alt={t('icon')} width={80}
                                                 height={"auto"}/>
                                        )}
                                    </td>
                                    <td>
                                        {resourceType.title}
                                    </td>
                                    <td>
                                        {resourceType.description}
                                    </td>
                                    <td>
                                        {resource.preview && (
                                            <img src={resource.preview.url} alt={t('preview')} width={80}
                                                 height={"auto"}/>
                                        )}
                                        {resourceType.preview && (
                                            <img src={resourceType.preview.url} alt={t('preview')} width={80}
                                                 height={"auto"}/>
                                        )}
                                    </td>
                                    <td>
                                        <Gap size={"medium"}>
                                    <span className={"clickable"} onClick={() => selectResource(resource)}>
                                        {t('update')}
                                    </span>
                                            <span className={"clickable"} onClick={() => deleteResource(resource)}>
                                        {t('delete')}
                                    </span>
                                        </Gap>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>

                    <Modal visible={mutateModalVisible} onCancel={closeModal} title={t('createResource')}>
                        <form onSubmit={handleSubmit(saveResource)}>
                            <input type={"hidden"} {...register('id')} />
                            <Space direction={"column"} size={"large"}>
                                <div className={"w-100"}>
                                    <InputLabel>
                                        {t('title')}
                                    </InputLabel>
                                    <Controller control={control} name={"resourceTypeId"} render={({field}) => (
                                        <select className={"select"} name={field.name} onChange={field.onChange}>
                                            <option value={undefined}>{t('select')}</option>
                                            {resourceTypes?.map((resourceType) => (
                                                <option key={resourceType.id}
                                                        value={resourceType.id}>{resourceType.title}</option>
                                            ))}
                                        </select>
                                    )}/>
                                </div>

                                <Controller control={control} defaultValue={[]} name={"agreementIds"}
                                            render={({field}) => (
                                                <Dropdown position={"Under Left"} toggle={
                                                    <div className={"w-100"}>
                                                        <InputLabel>
                                                            {t('agreements')}
                                                        </InputLabel>
                                                        <div className={"select"}>
                                                            {watch('agreementIds')?.length > 0 ? (
                                                                agreements?.filter(x => watch('agreementIds')?.includes(x.id)).map(x => x.nickname).join(', ')
                                                            ) : (
                                                                t('all')
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                                >
                                                    <>
                                                        <DropdownItem onClick={() => field.onChange(null)} key={"all"}>
                                                            {t('all')}
                                                        </DropdownItem>
                                                        {agreements?.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(x => (
                                                            <DropdownItem
                                                                onClick={() => field.onChange(field.value?.includes(x.id) ? [...field.value.filter(v => v !== x.id)] : [...field.value, x.id])}
                                                                key={x.id}>
                                                                <FlexRow justify={"space-between"}>
                                                <span>
                                                    {x.nickname}
                                                </span>
                                                                    {field.value?.includes(x.id) && (
                                                                        <CheckmarkCircle/>
                                                                    )}
                                                                </FlexRow>
                                                            </DropdownItem>
                                                        ))}
                                                    </>
                                                </Dropdown>
                                            )}/>

                                <div className={"w-100"}>
                                    <InputLabel>
                                        {t('preview')}
                                    </InputLabel>
                                    <FlexRow justify={"space-between"}>
                                        <div>
                                            {watch('preview') && (
                                                <img src={watch('preview').url} alt={t('preview')} width={100}
                                                     height={100}/>
                                            )}
                                        </div>
                                        <FileUploadButton multiple callback={file => setValue('preview', file)}/>
                                    </FlexRow>
                                </div>

                                <div className={"w-100"}>
                                    <InputLabel>
                                        {t('resourceFile')}
                                    </InputLabel>
                                    <FlexRow justify={"space-between"}>
                                        <div>
                                            {watch('resourceFile') && (
                                                <ReportIcon/>
                                            )}
                                        </div>
                                        <FileUploadButton multiple callback={file => setValue('resourceFile', file)}/>
                                    </FlexRow>
                                </div>

                                <FlexRow justify={"end"}>
                                    <Button outline state={isSavingResource ? 'Loading' : 'None'} type={"submit"}
                                            text={t('save')}/>
                                </FlexRow>
                            </Space>
                        </form>
                    </Modal>

                    <Modal title={t('deleteResourceType')} visible={deleteModalVisible} onCancel={closeModal}>
                        <form onSubmit={handleSubmit(confirmDeleteResource)}>
                            <Space>
                                <div className={"w-100"}>
                                    {t('deleteResourceConfirmation')}
                                </div>

                                <FlexRow justify={"end"}>
                                    <Button color={"danger"} disabled={isDeletingResource} type={"submit"}
                                            state={isDeletingResource ? 'Loading' : 'None'} text={t('delete')}/>
                                </FlexRow>
                            </Space>
                        </form>
                    </Modal>
                </>
            )}
        </Box>
    )

}

export default ResourcesPage
