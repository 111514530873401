import {SVGProps} from "react";

const ResourcesIcon = (props: SVGProps<SVGSVGElement>) => {

    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15.7931 2.88338C15.7353 2.38604 15.3126 2 14.7998 2C14.2475 2 13.7998 2.44772 13.7998 3V5L13.8065 5.11662C13.8643 5.61396 14.287 6 14.7998 6C15.3521 6 15.7998 5.55228 15.7998 5V3L15.7931 2.88338ZM12.7328 5C12.7328 4.44772 12.2851 4 11.7328 4H4.7998L4.68318 4.00673C4.18584 4.06449 3.7998 4.48716 3.7998 5V21L3.80653 21.1166C3.8643 21.614 4.28697 22 4.7998 22H17.7998L17.9164 21.9933C18.4138 21.9355 18.7998 21.5128 18.7998 21V13L18.7931 12.8834C18.7353 12.386 18.3126 12 17.7998 12L17.6832 12.0067C17.1858 12.0645 16.7998 12.4872 16.7998 13V20H5.7998V6H11.7328L11.8494 5.99327C12.3468 5.93551 12.7328 5.51284 12.7328 5ZM14.7998 17C14.7998 16.4477 14.3521 16 13.7998 16H8.7998L8.68318 16.0067C8.18584 16.0645 7.7998 16.4872 7.7998 17C7.7998 17.5523 8.24752 18 8.7998 18H13.7998L13.9164 17.9933C14.4138 17.9355 14.7998 17.5128 14.7998 17ZM8.7998 8H13.7998C14.3521 8 14.7998 8.44772 14.7998 9V13C14.7998 13.5523 14.3521 14 13.7998 14H8.7998C8.24752 14 7.7998 13.5523 7.7998 13V9C7.7998 8.44772 8.24752 8 8.7998 8ZM12.7998 12V10H9.7998V12H12.7998ZM19.0927 3.29289C19.4832 2.90237 20.1164 2.90237 20.5069 3.29289C20.8674 3.65338 20.8951 4.22061 20.5901 4.6129L20.5069 4.70711L18.5069 6.70711C18.1164 7.09763 17.4832 7.09763 17.0927 6.70711C16.7322 6.34662 16.7045 5.77939 17.0095 5.3871L17.0927 5.29289L19.0927 3.29289ZM21.7998 9C21.7998 8.44772 21.3521 8 20.7998 8H18.7998L18.6832 8.00673C18.1858 8.06449 17.7998 8.48716 17.7998 9C17.7998 9.55228 18.2475 10 18.7998 10H20.7998L20.9164 9.99327C21.4138 9.93551 21.7998 9.51284 21.7998 9Z"
                  fill="#616161"/>
        </svg>
    )

}

export default ResourcesIcon
