import React, {useMemo, useState} from "react";
import styles from './List.module.scss';
import FlexRow from "Components/FlexRow";
import The0MissionIcon from "../Assets/Icons/The0MissionIcon";
import CaretDownIcon from "../Assets/Icons/CaretDownIcon";
import Table from "Components/Table";

export interface Column {
    dataIndex?: string,
    customRender?: (text?, record?) => React.ReactElement,
    textRender?: (text?, record?) => string,
    title?: string;
    allowOrderBy?: boolean;
    orderByValueFn?: (element: any) => string | number | Date;
    size?: 'auto' | 'max-content' | 'min-content' | string,
}

interface Props {
    loading?: boolean;
    data?: any[];
    columns: Column[];
    emptyContent?: React.ReactElement;
    emptyText: string;
    keyDataIndex: string;
    defaultOrderBy?: string;
}
const List = ({loading, data, columns, emptyContent, emptyText, keyDataIndex, defaultOrderBy}: Props) => {

    const [orderByField, setOrderByField] = useState<string>(defaultOrderBy ?? '')
    const [orderByDirection, setOrderByDirection] = useState<'ASCENDING' | 'DESCENDING'>('ASCENDING');

    const renderColumn = (column: Column, record: any, index) => {

        return column.customRender!(column.dataIndex ? record[column.dataIndex] : '', record)
    }

    const setSort = (column: Column) => {
        if (!column.allowOrderBy || !column.dataIndex) {
            return;
        }

        let newDirection = orderByDirection
        let newField = column.dataIndex;

        if (orderByField === column.dataIndex) {
            newDirection = orderByDirection === 'ASCENDING' ? 'DESCENDING' : 'ASCENDING'
        } else {
            newDirection = 'ASCENDING'
        }

        setOrderByField(newField)
        setOrderByDirection(newDirection)
    }

    const rows = useMemo(() => {
        let rowData = data;
        let column = columns.find(x => x.dataIndex === orderByField)

        if (orderByField && orderByDirection && !!column && !!column.orderByValueFn) {
            rowData?.sort((a, b) => {
                let aValue = column!.orderByValueFn!(a).toString().toLowerCase();
                let bValue = column!.orderByValueFn!(b).toString().toLowerCase();

                if (orderByDirection === 'ASCENDING') {
                    return aValue > bValue ? 1 : -1
                } else {
                    return aValue > bValue ? -1 : 1
                }
            })
        }

        return rowData?.map(record => (
            <tr key={record[keyDataIndex]}>
                {columns.map((column, index) => (
                    <td key={index} style={{inlineSize: 'min-content'}}>
                        {column.customRender ?
                            <>
                                {renderColumn(column, record, index)}
                            </>
                            : (
                                <>
                                    {column.textRender ? column.textRender(record[column.dataIndex!], record) : record[column.dataIndex!]}
                                </>

                            )}
                    </td>
                ))}
            </tr>
        ))
    }, [columns, data, keyDataIndex, orderByField, orderByDirection])

    if (loading) {
        return (
            <>
                <div className={`${styles.loadingContainer}`}>
                    <FlexRow justify={"center"} direction={"column"} className={"h-100"}>
                        <The0MissionIcon spin width={40} height={40} />
                    </FlexRow>
                </div>
            </>
        )
    }

    if (data?.length === 0) {
        return (
            <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                {emptyContent ? emptyContent : emptyText}
            </FlexRow>
        )
    }

    return (
        <Table>
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th style={{verticalAlign: 'baseline'}} key={index} className={`${column.allowOrderBy && 'clickable'}`} onClick={() => setSort(column)}>
                            {column.title} {orderByField === column.dataIndex && (<CaretDownIcon direction={orderByDirection} />)}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
    )
}

export default List
