import DefaultPageLayout from "../../Layouts/DefaultPageLayout";
import React, {useEffect, useState} from "react";
import styles from './LoginPage.module.scss';
import {StyledText} from "../../Components/Text";
import Box from "Components/Box";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {
    applyActionCode,
    confirmPasswordReset, sendEmailVerification,
    verifyPasswordReset
} from "../../FirebaseSetup/auth";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import {useAuthProvider} from "../../Providers/AuthProvider";
import {usePasswordChecks} from "../../Hooks/UsePasswordChecks";
import FlexRow from "../../Components/FlexRow";
import The0MissionIcon from "../../Assets/Icons/The0MissionIcon";
import InputLabel from "../../Components/InputLabel";
import {ControlledInput} from "../../Components/Input";
import Gap from "../../Components/Gap";
import CircledCheckmark from "../../Assets/Icons/CircledCheckmark";
import CircledX from "../../Assets/Icons/CircledX";
import OnboardingBoxContent from "Features/Onboarding/Components/OnboardingBoxContent";
import Button from "../../Components/Button";
import useToast from "../../Hooks/useToast";

const AuthActionsPage = () => {

    const { t } = useTranslation()
    const { showErrorToast } = useToast();

    const { firebaseUser } = useAuthProvider();

    let query = new URLSearchParams(useLocation().search);

    const { forceReload } = useAuthProvider();

    let mode = query.get('mode');
    let oobCode = query.get('oobCode') ?? '';

    const { control, handleSubmit} = useForm<{email: string, password: string, passwordCheck: string, newsletterAllowed: boolean, acceptTerms: boolean}>();

    const navigate = useNavigate();
    const [codeValidated, setCodeValidated] = useState(false);
    const [ validatingCode, setValidatingCode] = useState(false);
    const [resettingPassword, setResettingPassword] = useState(false);
    const [ actionCompleted, setActionCompleted ] = useState(false);
    const [error, setError] = useState('');
    const [error2, setError2] = useState('');

    const [passwordChecks, ,setFirstPassword, setSecondPassword] = usePasswordChecks(); // empty comma is needed here to make sure the correct methods are used

    const validateCode = async () => {
        if (codeValidated || validatingCode) {
            return;
        }

        if (mode === 'resetPassword') {
            setValidatingCode(true);

            try {
                await verifyPasswordReset(oobCode);

                forceReload().then(() => {
                    setCodeValidated(true);
                })
            }
            catch (e) {
                setError(t('auth:actions.linkHasBeenUsedOrExpired'));
            }
            finally {
                setValidatingCode(false);
            }
        }

        if (mode === 'verifyEmail') {
            setValidatingCode(true);


            try {
                if (!firebaseUser?.emailVerified) {
                    await applyActionCode(oobCode);
                }

                forceReload().then(() => {
                    setCodeValidated(true);
                })

                setActionCompleted(true)
                setError(t('auth:actions.emailVerified'))
                setError2(t('auth:actions.EmailVerifiedMessageDescription'))
                setTimeout(() => {
                    navigate('dashboard')
                }, 2000)
            }
            catch (e: any) {
                if (e.code === 'auth/invalid-action-code') {
                    setError(t('auth:actions.linkHasBeenUsedOrExpired'));
                }
                forceReload().then(() => {
                    setCodeValidated(true);
                })
            }
            finally {
                setValidatingCode(false);
            }
        }
    }

    useEffect(() => {
        validateCode().then();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    const resetPassword = (data: { password: string }) => {
        setError('');
        setError2('');

        if (passwordChecks.filter(x => !x.fulfilled).length > 0) {
            showErrorToast(t('auth:signup.passwordRequirementsNotMet'));
            return;
        }

        setResettingPassword(true)

        confirmPasswordReset(oobCode, data.password).then(
            () => {
                setActionCompleted(true)
                setError(t('auth:actions.passwordUpdatedMessage'))
                setError2(t('auth:actions.passwordUpdatedMessageDescription'))
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
            },
            (e) => {
                showErrorToast(t('somethingWentWrong'));
            }
        ).catch((e) => {
            if (e.code === 'auth/network-request-failed') {
                showErrorToast(t('somethingWentWrong'));
            }
        }).finally(() => {
            setResettingPassword(false)
        })
    };

    const getH1 = () => {
        if (!!error) {
            return error;
        }

        switch (mode) {
            case 'resetPassword':
                return t('auth:actions.resetPassword.h1');
            case 'verifyEmail':
                return t('auth:actions.emailVerifiedLoading.h1');
        }
    }

    const getH2 = () => {
        if (!!error) {
            return error2;
        }

        switch (mode) {
            case 'resetPassword':
                return t('auth:actions.resetPassword.h2');
            case 'verifyEmail':
                return t('auth:actions.emailVerifiedLoading.h2');
        }
    }

    const getNewVerifyLink = async () => {
        await sendEmailVerification(firebaseUser);

        setError(t('auth:actions.newVerifyLinkSent'));
    }

     return (
        <DefaultPageLayout contentMaxWidth={1440}>
            <div className={styles.container}>

                <div className={styles.headline}>
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {getH1()}
                    </StyledText>
                    <StyledText type={"Body"}>
                        {getH2()}
                    </StyledText>
                </div>

                <FlexRow justify={'center'}>
                    {(validatingCode || actionCompleted) && (
                        <The0MissionIcon width={105} height={105} spin/>
                    )}

                    {(mode === 'verifyEmail' && (!validatingCode && error === t('auth:actions.linkHasBeenUsedOrExpired'))) && (
                        <Button text={t('auth:actions.newVerifyLink')} onClick={getNewVerifyLink} />
                    )}

                    {(mode === 'resetPassword' && (!validatingCode && codeValidated && !actionCompleted)) && (
                        <Box style={{width: 840}}>
                            <form onSubmit={handleSubmit(resetPassword)}>
                                <OnboardingBoxContent content={(
                                    <>
                                        {mode === 'resetPassword' && (
                                            <>
                                                <div>
                                                    <InputLabel required>
                                                        {t('password')}
                                                    </InputLabel>
                                                    <ControlledInput name={'password'} placeholder={t('yourPassword')}
                                                                     type={"password"} control={control}
                                                                     onChange={newValue => setFirstPassword(newValue.target.value)}
                                                                     rules={{required: true}}/>
                                                </div>

                                                <Gap size={"small"} direction={"vertical"}>
                                                    {passwordChecks.map((x, index) => (
                                                        <Gap size={"small"} key={index}>
                                                            {x.fulfilled ? <CircledCheckmark/> : <CircledX/>}
                                                            <StyledText type={"Caption"} color={"secondary"}>
                                                                {x.message}
                                                            </StyledText>
                                                        </Gap>
                                                    ))}
                                                </Gap>

                                                <div>
                                                    <InputLabel required>
                                                        {t('repeatPassword')}
                                                    </InputLabel>
                                                    <ControlledInput name={'repeatPassword'} placeholder={t('repeatYourPassword')}
                                                                     type={"password"} control={control}
                                                                     onChange={newValue => setSecondPassword(newValue.target.value)}
                                                                     rules={{required: true}}/>
                                                </div>
                                            </>
                                        )}
                                        </>
                                    )}
                                    buttons={(
                                        <Gap size={"small"} className={"w-100 flex space-between"}>
                                            <div />
                                            <Button text={t('continue')} type={"submit"} state={resettingPassword ? "Loading" : "None"}/>
                                        </Gap>
                                    )}
                                />
                            </form>
                        </Box>
                    )}
                </FlexRow>
            </div>
        </DefaultPageLayout>
     )
}

export default AuthActionsPage
