import {StyledText} from "../../../Components/Text";
import React, {useEffect} from "react";
import Box from "Components/Box";
import Gap from "Components/Gap";
import {useAuthProvider} from "../../../Providers/AuthProvider";
import { useTranslation } from "react-i18next";
import {useForm} from "react-hook-form";
import styles from "./ChangePasswordPage.module.scss";
import InputLabel from "../../../Components/InputLabel";
import {ControlledInput} from "../../../Components/Input";
import Separator from "../../../Components/Separator";
import FlexRow from "../../../Components/FlexRow";
import Button from "../../../Components/Button";
import {useUpdateAgreementV2} from "../../../Apis/AgreementApi";
import useToast from "../../../Hooks/useToast";
import AgreementSelector from "../../../Features/Dashboard/Components/AgreementSelector";
import useSelectedAgreement from "../../../Features/Dashboard/Hooks/useSelectedAgreement";
import {MutateAgreementInfoModel} from "../../../Apis/Models/MutateAgreementInfoModel";

const ContactInfoPage = () => {

    const { userContext } = useAuthProvider();

    const { showSuccessToast, showErrorToast } = useToast();

    const { t } = useTranslation();

    const { control, handleSubmit, reset } = useForm<MutateAgreementInfoModel>();
    const agreement = useSelectedAgreement();

    const { mutateAsync: updateContactInfoMutation, isLoading, isSuccess } = useUpdateAgreementV2();

    useEffect(() => {
        if (agreement && userContext) {
            reset({
                firstname: userContext.firstname,
                lastname: userContext.lastname,
                title: agreement.title,
                email: userContext.email,
                phoneNumber: agreement.phoneNumber,
                invoiceEmail: agreement.invoiceEmail
            })

        }
    }, [agreement, userContext, reset])

    const onSubmit = async (data: MutateAgreementInfoModel) => {
        try {
            await updateContactInfoMutation({...data, id: agreement!.id});

            showSuccessToast(`${t('success')} ${t('contactInfoUpdated')}` );
        } catch (e: any) {
            showErrorToast(t(e.data));
        }
    }

    return (
        <Box>
            <Gap direction={"vertical"} size={"medium"}>
                <div>
                    <StyledText tag={"div"} type={"Lead"} weight={"Bold"} color={"secondary"}>{t('changeContactInfo')}</StyledText>
                    <StyledText type={"Body"} weight={"Regular"} color={"secondary"}>{userContext?.firstname} {userContext?.lastname}</StyledText>
                </div>

                <AgreementSelector requireSelect position={"Under Left"}  />

                {!!agreement && (
                    <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                        <Gap size={"large"} direction={"vertical"}>

                            <Gap direction={"vertical"} size={"medium"} className={"w-100"}>
                                <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                                    {t('contactPerson')}
                                </StyledText>
                                <Gap size={"medium"} className={"w-100"}>
                                    <div className={styles.inputFieldLength}>
                                        <InputLabel required>
                                            {t('firstName')}
                                        </InputLabel>
                                        <ControlledInput name={'firstname'} placeholder={t('firstName')} control={control} rules={{required: true}} className={styles.inputFieldLength} />
                                    </div>

                                    <div className={styles.inputFieldLength} >
                                        <InputLabel required>
                                            {t('lastName')}
                                        </InputLabel>
                                        <ControlledInput name={'lastname'} placeholder={t('lastname')} control={control} rules={{required: true}} className={styles.inputFieldLength} />
                                    </div>
                                </Gap>

                                <div className={styles.inputFieldLength}>
                                    <InputLabel required>
                                        {t('title')}
                                    </InputLabel>
                                    <ControlledInput name={'title'} placeholder={t('title')} control={control} className={styles.inputFieldLength}/>
                                </div>

                                <Separator />

                                 <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                                    {t('contactDetails')}
                                </StyledText>
                                <Gap size={"medium"} className={"w-100"}>
                                    <div className={styles.inputFieldLength}>
                                        <InputLabel required>
                                            {t('email')}
                                        </InputLabel>
                                        <ControlledInput name={'email'} placeholder={t('email')} control={control} rules={{required: true}} className={styles.inputFieldLength} />
                                    </div>

                                    <div className={styles.inputFieldLength} >
                                        <InputLabel required>
                                            {t('phone')}
                                        </InputLabel>
                                        <ControlledInput name={'phoneNumber'} placeholder={t('phone')} control={control} rules={{required: true}} className={styles.inputFieldLength} />
                                    </div>
                                </Gap>

                                <div className={styles.inputFieldLength}>
                                    <InputLabel required>
                                        {t('invoiceEmail')}
                                    </InputLabel>
                                    <ControlledInput name={'invoiceEmail'} placeholder={t('invoiceEmail')} control={control} rules={{required: true}} className={styles.inputFieldLength}/>
                                </div>

                                <Gap className={"w-100"} direction={"vertical"} size={"small"}>
                                    <Separator/>

                                    <FlexRow justify={"end"}>
                                        <Button text={t('saveChanges')} state={isLoading ? "Loading" : isSuccess ? "Success" : "None"} type={"submit"}/>
                                    </FlexRow>
                                </Gap>
                            </Gap>
                        </Gap>
                    </form>
                )}
            </Gap>
        </Box>
    )

}

export default ContactInfoPage
