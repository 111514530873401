import styles from './OnboardingPage.module.scss'
import Gap from "../../Components/Gap";
import {StyledText} from "../../Components/Text";
import Button from "../../Components/Button";
import Steps from "../../Features/Onboarding/Components/Steps";
import DefaultPageLayout from "../../Layouts/DefaultPageLayout";
import {useAuthProvider} from "../../Providers/AuthProvider";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import OnboardingBoxContent from "../../Features/Onboarding/Components/OnboardingBoxContent";
import Grid from "../../Components/Grid";
import InputLabel from "../../Components/InputLabel";
import {ControlledInput} from "../../Components/Input";
import Box from "../../Components/Box";
import {useForm} from "react-hook-form";
import CircledCheckmark from "../../Assets/Icons/CircledCheckmark";
import CircledX from "../../Assets/Icons/CircledX";
import React, {useState} from "react";
import {usePasswordChecks} from "../../Hooks/UsePasswordChecks";
import Checkbox from "Components/Checkbox";
import FlexRow from 'Components/FlexRow';
import CalendlyBooker from "../../Features/Onboarding/Components/CalendlyBooker";
import {useCreateAgreementV2} from "../../Features/Onboarding/Services/OnboardingApi";
import {loginWithCustomToken, sendEmailVerification} from "../../FirebaseSetup/auth";
import {OnboardingValuesV2} from "../../Features/Onboarding/Services/OnboardingValues";
import useToast from 'Hooks/useToast';


const OnboardingPage = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {userContext} = useAuthProvider();

    const {control, handleSubmit} = useForm<OnboardingValuesV2>();

    const [passwordChecks, , setFirstPassword] = usePasswordChecks(false); // empty comma is needed here to make sure the correct methods are used

    const { mutateAsync: createAgreementMutation} = useCreateAgreementV2();

    const { showErrorToast } = useToast();

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onSubmit = async (data: OnboardingValuesV2) => {
        if (passwordChecks.filter(x => !x.fulfilled).length > 0) {
            showErrorToast(t('auth:signup.passwordRequirementsNotMet'));

            return;
        }

        setIsLoading(true);

        try {
            const response = await createAgreementMutation(data);

            if (!!response.token) {
                const firebaseUser = await loginWithCustomToken(response.token);

                await sendEmailVerification(firebaseUser.user);

                setIsLoading(false);
                navigate("/verifyEmail");
            } else {
                navigate(`/dashboard/${response.agreementId}/onboarding`);
            }
        }
        catch (error: any) {
            console.log(error)
            setIsLoading(false);
        }
    }

    return (
        <DefaultPageLayout contentMaxWidth={1440} menuContent={(
            <Gap size={"small"}>
                {!userContext && (
                    <>
                        <StyledText type={'ButtonLarge'}>{t('onboarding.2.existingUser')}</StyledText>
                        <Button outline text={t('onboarding.2.signInHere')} onClick={() => navigate("/v2/login")}/>
                    </>
                )}
            </Gap>
        )}>
            <div className={styles.content}>

                <div className={styles.headline}>
                    <Steps currentStep={1} totalSteps={2}/>
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {t('onboarding.2.companyDetails')}
                    </StyledText>
                </div>

                <Box style={{width: '100%'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <OnboardingBoxContent
                            content={(
                                <>
                                    <Grid gridTemplateColumns={"1fr 1fr"} columnGap={8}>
                                        <div>
                                            <InputLabel>
                                                {t('firstName')} *
                                            </InputLabel>
                                            <ControlledInput name={'firstName'} placeholder={t('yourFirstName')} control={control} rules={{required: true}}/>
                                        </div>

                                        <div>
                                            <InputLabel>
                                                {t('lastName')} *
                                            </InputLabel>
                                            <ControlledInput name={'lastName'} placeholder={t('yourLastName')} control={control} rules={{required: true}}/>
                                        </div>
                                    </Grid>

                                    <div>
                                        <InputLabel>
                                            {t('email')} *
                                        </InputLabel>
                                        <ControlledInput name={'email'} placeholder={t('yourEmail')} control={control} rules={{required: true}}/>
                                    </div>

                                    <div>
                                        <InputLabel required>
                                            {t('password')}
                                        </InputLabel>
                                        <ControlledInput name={'password'} placeholder={t('yourPassword')} type={"password"} control={control} onChange={newValue => setFirstPassword(newValue.target.value)} rules={{required: true}}/>
                                    </div>

                                    <Gap size={"small"} direction={"vertical"}>
                                        {passwordChecks.map((x, index) => (
                                            <Gap size={"small"} key={index}>
                                                {x.fulfilled ? <CircledCheckmark/> : <CircledX/>}
                                                <StyledText type={"Caption"} color={"secondary"}>
                                                    {x.message}
                                                </StyledText>
                                            </Gap>
                                        ))}
                                    </Gap>

                                    <div>
                                        <InputLabel>
                                            {t('companyVatNumber')}
                                        </InputLabel>
                                        <ControlledInput name={'companyVatNumber'} placeholder={t('companyVatNumber')} control={control} rules={{required: true, minLength: 8}}/>
                                    </div>

                                    <FlexRow>
                                        <Checkbox name={"acceptTerms"} control={control} label={<a style={{color: 'black', textDecoration: 'none'}} target={"_blank"} rel={"noreferrer"} href={"https://www.the0mission.dk/privatlivspolitik/"}>{t('iAccept')}<span style={{textDecoration: 'underline'}}>{t('privacyPolicy')}</span></a>} rules={{required: true}}/>
                                        <Button text={t('continue')} type={"submit"} state={isLoading ? "Loading" : "None"} disabled={isLoading} />
                                    </FlexRow>
                                </>
                            )}
                        />
                    </form>
                </Box>

                <CalendlyBooker />
            </div>
        </DefaultPageLayout>
    )

}

export default OnboardingPage
