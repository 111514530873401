import {Trans, useTranslation} from "react-i18next";
import {useDeleteResourceType, useResourceTypes, useSaveResourceType} from "../../../Apis/Admin/ResourceTypeAdminApi";
import {useForm} from "react-hook-form";
import {ResourceTypeDto} from "../../../Apis/Models/ResourceTypeDto";
import React, {useState} from "react";
import Box from "../../../Components/Box";
import FlexRow from "../../../Components/FlexRow";
import {StyledText} from "../../../Components/Text";
import Button from "../../../Components/Button";
import Table from "../../../Components/Table";
import Gap from "../../../Components/Gap";
import CaretIcon from "../../../Assets/Icons/V2/CaretIcon";
import Modal from "../../../Components/Modal";
import Space from "../../../Components/Space";
import InputLabel from "../../../Components/InputLabel";
import {ControlledInput} from "../../../Components/Input";
import FileUploadButton from "../../../Components/FileUploadButton";
import useToast from "../../../Hooks/useToast";

const ResourceTypesPage = () => {

    const { t } = useTranslation();

    const { showErrorToast } = useToast();

    const { data: resourceTypes } = useResourceTypes();
    const { mutateAsync: saveResourceTypeMutation, isLoading: isSavingResourceType } = useSaveResourceType();
    const { mutateAsync: deleteResourceTypeMutation, isLoading: isDeletingResourceType } = useDeleteResourceType();

    const { handleSubmit, register, control, reset, setValue, watch } = useForm<ResourceTypeDto>();

    const [mutateModalVisible, setMutateModalVisible] = useState<boolean>(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

    const onSubmit = async (data: ResourceTypeDto) => {
        if (!data.icon?.id) {
            showErrorToast(t('iconRequired'));
            return;
        }

        await saveResourceTypeMutation(data);

        closeModal();
    }

    const closeModal = () => {
        reset();
        setMutateModalVisible(false);
        setDeleteModalVisible(false)
    }

    const selectResourceType = (resourceType: ResourceTypeDto) => {
        reset(resourceType)
        setMutateModalVisible(true);
    }

    const deleteResourceType = async (resourceType: ResourceTypeDto) => {
        reset(resourceType);
        setDeleteModalVisible(true);
    }

    const confirmDeleteResourceType = async (data: ResourceTypeDto) => {
        await deleteResourceTypeMutation(data);

        setDeleteModalVisible(false);
        reset()
    }

    const reorderResourceTypes = async (resourceType: ResourceTypeDto, move: 'Up' | 'Down' ) => {

        const movedResource = resourceTypes!.find(r => r.orderIndex === resourceType.orderIndex + (move === 'Up' ? 1 : -1))!;
        movedResource.orderIndex = resourceType.orderIndex;
        resourceType.orderIndex = resourceType.orderIndex + (move === 'Up' ? 1 : -1);

        await Promise.all([
            saveResourceTypeMutation(resourceType),
            saveResourceTypeMutation(movedResource)
        ])
    }

    return (
        <Box>
            <FlexRow justify={"space-between"}>
                <StyledText type={"Lead"} weight={"Bold"} color={"secondary"}>{t('resourceTypes')}</StyledText>

                    <Button color={"link"} onClick={() => setMutateModalVisible(true)} text={`+ ${t('createResourceType')}`} />
            </FlexRow>

            <Table>
                <thead>
                    <tr>
                        <th>

                        </th>
                        <th>
                            {t('title')}
                        </th>
                        <th>
                            {t('description')}
                        </th>
                        <th>
                            {t('link')}
                        </th>
                        <th>
                            {t('icon')}
                        </th>
                        <th>
                            {t('preview')}
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                {resourceTypes?.map((resourceType, index) => (
                    <tr key={resourceType.id}>
                        <td>
                            <Gap size={"medium"}>
                                {index > 0 && (
                                    <CaretIcon onClick={() => reorderResourceTypes(resourceType, 'Down')} className={"clickable"} style={{transform: 'rotate(180deg)'}} />
                                )}
                                {index < resourceTypes?.length - 1 && (
                                    <CaretIcon onClick={() => reorderResourceTypes(resourceType, 'Up')} className={"clickable"} />
                                )}
                            </Gap>
                        </td>
                        <td>
                            {resourceType.title}
                        </td>
                        <td>
                            {resourceType.description}
                        </td>
                        <td>
                            {!!resourceType.link && (
                                <a href={resourceType.link} target={"_blank"} rel={"noreferrer"}>{resourceType.link}</a>
                            )}
                        </td>
                        <td>
                            {resourceType.icon && (
                                <img src={resourceType.icon.url} alt={t('icon')} width={80} height={"auto"} />
                            )}
                        </td>
                        <td>
                            {resourceType.preview && (
                                <img src={resourceType.preview.url} alt={t('preview')} width={80} height={"auto"} />
                            )}
                        </td>
                        <td>
                            <Gap size={"medium"}>
                                <span className={"clickable"} onClick={() => selectResourceType(resourceType)}>
                                    {t('update')}
                                </span>
                                <span className={"clickable"} onClick={() => deleteResourceType(resourceType)}>
                                    {t('delete')}
                                </span>
                            </Gap>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>

            <Modal visible={mutateModalVisible} onCancel={closeModal} title={t('createResourceType')} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type={"hidden"} {...register('id')} />
                    <Space direction={"column"} size={"large"}>
                        <div className={"w-100"}>
                            <InputLabel required>
                                {t('title')}
                            </InputLabel>
                            <ControlledInput rules={{required: true}} placeholder={t('title')} name={"title"} control={control} />
                        </div>

                        <div className={"w-100"}>
                            <InputLabel required>
                                {t('description')}
                            </InputLabel>
                            <ControlledInput rules={{required: true}} placeholder={t('description')} name={"description"} control={control} />
                        </div>

                        <div className={"w-100"}>
                            <InputLabel>
                                {t('link')}
                            </InputLabel>
                            <ControlledInput placeholder={t('link')} name={"link"} control={control} />
                        </div>

                        <div className={"w-100"}>
                            <InputLabel required>
                                {t('icon')}
                            </InputLabel>
                            <FlexRow justify={"space-between"}>
                                <div>
                                    {watch('icon') && (
                                        <img src={watch('icon').url} alt={t('icon')} width={100} height={100} />
                                    )}
                                </div>
                                <FileUploadButton multiple callback={file => setValue('icon', file)} />
                            </FlexRow>
                        </div>

                        <div className={"w-100"}>
                            <InputLabel>
                                {t('preview')}
                            </InputLabel>
                            <FlexRow justify={"space-between"}>
                                <div>
                                    {watch('preview') && (
                                        <img src={watch('preview').url} alt={t('preview')} width={100} height={100} />
                                    )}
                                </div>
                                <FileUploadButton multiple callback={file => setValue('preview', file)} />
                            </FlexRow>
                        </div>

                        <FlexRow justify={"end"}>
                            <Button outline state={isSavingResourceType ? 'Loading' : 'None'} type={"submit"} text={t('save')} />
                        </FlexRow>
                    </Space>
                </form>
            </Modal>

            <Modal title={t('deleteResourceType')} visible={deleteModalVisible} onCancel={closeModal}>
                <form onSubmit={handleSubmit(confirmDeleteResourceType)}>
                    <Space>
                        <div className={"w-100"}>
                            <Trans i18nKey="deleteResourceTypeConfirmation" values={{title: watch('title')}} />
                        </div>

                        <FlexRow justify={"end"}>
                            <Button color={"danger"} disabled={isDeletingResourceType} type={"submit"} state={isDeletingResourceType ? 'Loading' : 'None'} text={t('delete')} />
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </Box>
    )


}

export default ResourceTypesPage
