const PreviewCircleIcon = () => {

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#9E9E9E"/>
            <path d="M21.3334 13.3333H16.0001C15.6465 13.3333 15.3073 13.4738 15.0573 13.7238C14.8072 13.9739 14.6667 14.313 14.6667 14.6666V25.3333C14.6667 25.6869 14.8072 26.0261 15.0573 26.2761C15.3073 26.5262 15.6465 26.6666 16.0001 26.6666H24.0001C24.3537 26.6666 24.6928 26.5262 24.9429 26.2761C25.1929 26.0261 25.3334 25.6869 25.3334 25.3333V17.3333M21.3334 13.3333L25.3334 17.3333M21.3334 13.3333V17.3333H25.3334M22.6667 20.6666H17.3334M22.6667 23.3333H17.3334M18.6667 18H17.3334" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )

}

export default PreviewCircleIcon
