import * as React from "react"
import { SVGProps } from "react"

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5ZM15 25C9.47715 25 5 20.5229 5 15C5 9.47715 9.47715 5 15 5C20.5229 5 25 9.47715 25 15C25 20.5229 20.5229 25 15 25ZM16.6221 11.6052C17.1123 11.119 17.9037 11.1223 18.3899 11.6124L18.3971 11.6197C18.8833 12.1098 18.88 12.9013 18.3899 13.3874L16.7649 14.9999L18.3899 16.6124L18.3971 16.6197C18.8833 17.1098 18.88 17.9013 18.3899 18.3874L18.3826 18.3947C17.8925 18.8808 17.101 18.8776 16.6149 18.3874L15.0024 16.7624L13.3899 18.3874L13.3826 18.3947C12.8925 18.8808 12.101 18.8776 11.6149 18.3874L11.6076 18.3802C11.1215 17.89 11.1247 17.0986 11.6149 16.6124L13.2399 14.9999L11.6149 13.3874C11.1247 12.8973 11.1247 12.1026 11.6149 11.6124C12.105 11.1223 12.8997 11.1223 13.3899 11.6124L15.0024 13.2374L16.6149 11.6124L16.6221 11.6052Z"
              fill="#B386CF"/>
    </svg>

)

export default CloseIcon
