import { useTranslation } from "react-i18next";
import FlexRow from "Components/FlexRow";
import List from "Components/List";
import { useSaveTerms, useTerms } from "Apis/Admin/TermsApi";
import React, { useState } from "react";
import { CreateTermsModel } from "Apis/Models/CreateTermsModel";
import { AgreementType } from "Apis/Models/AgreementModel";
import CreateTermsModal from "../../../Features/Terms/Components/CreateTermsModal";
import ShowTermsModal from "../../../Features/Terms/Components/ShowTermsModal";
import DeleteTermsModal from "../../../Features/Terms/Components/DeleteTermsModal";
import Space from "Components/Space";
import Button from "Components/Button";
import Box from "../../../Components/Box";
import {StyledText} from "../../../Components/Text";

const TermsPage = () => {

    const { t } = useTranslation();

    const { data, isLoading } = useTerms();

    const { mutateAsync: createTermsMutation } = useSaveTerms();

    const [termsModalVisible, setTermsModalVisible] = useState<boolean>(false);

    const createTerms = async (data: CreateTermsModel) => {
        data.isCustomTerms = false;

        await createTermsMutation(data);

        setTermsModalVisible(false);
    }

    const getAgreementTypeText = (agreement: AgreementType) => {
        switch (agreement) {
            case "Custom":
                return t('agreement:Custom');
            case "Match":
                return t('agreement:Match');
            case "MatchAndCustom":
                return t('agreement:MatchAndCustom');
        }
    }

    return (
        <Box>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <StyledText type={"Lead"} weight={"Bold"} color={"secondary"}>{t('terms:title')}</StyledText>

                <Button color={"link"} onClick={() => setTermsModalVisible(true)} text={`+ ${t('terms:createNewTerms')}`} />
            </FlexRow>

            <List keyDataIndex={"id"} loading={isLoading} data={data?.filter(x => !x.isCustomTerms)} emptyText={t('terms:noTermsFound')} columns={[
                {
                    dataIndex: "name",
                    title: t('terms:name'),
                },
                {
                    dataIndex: "version",
                    title: t('terms:version'),
                },
                {
                    dataIndex: "defaultTerms",
                    title: t('terms:defaultTerms'),
                    textRender: (text) => text ? t('yes') : t('no'),
                },
                {
                    dataIndex: "agreementType",
                    title: t('agreement:type'),
                    textRender: (text) => getAgreementTypeText(text),
                },
                {
                    dataIndex: "unitSalesPrice",
                    title: t('terms:unitSalesPricePrMwh'),
                    textRender: (text) => `${text} DKK`,
                },
                {
                    title: t('functions'),
                    customRender: (value, data) => (
                        <Space direction={"row"} size={"large"}>
                            <DeleteTermsModal terms={data} button={(
                                <Button color={"link"} text={t('delete')} />

                            )} />
                            <ShowTermsModal terms={data} button={(
                                <Button color={"link"} text={t('terms:showTerms')} />
                            )}/>
                        </Space>
                    ),
                },
            ]} />

            <CreateTermsModal onSave={createTerms} onCancel={() => setTermsModalVisible(false)} visible={termsModalVisible} terms={data ?? []} />
        </Box>
    )
}

export default TermsPage
