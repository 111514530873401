import {useMutation} from "react-query";
import {request} from "../../../Apis/BaseApi";
import {OnboardingValuesV2} from "./OnboardingValues";
import {OnboardingResult} from "./OnboardingResult";

export const useCreateAgreementV2 = () => {
    return useMutation(async (data: OnboardingValuesV2) => {
        return await request<OnboardingResult>({
            url: `/agreement/onboarding/v2`,
            method: 'POST',
            data
        })
    });
}
