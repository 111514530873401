import {useMutation, useQuery, useQueryClient} from "react-query";
import {request} from "../BaseApi";
import {ResourceTypeDto} from "../Models/ResourceTypeDto";

export const ADMIN_RESOURCE_TYPE_CACHE_KEY = "ADMIN_RESOURCE_TYPE_CACHE_KEY";
export const useResourceTypes = () => {
    return useQuery([ADMIN_RESOURCE_TYPE_CACHE_KEY], async () => {
        return await request<ResourceTypeDto[]>({
            url: `/admin/resourceTypes`,
            method: 'GET',
        })
    })
}

export const useSaveResourceType = () => {

    const queryClient = useQueryClient();

    return useMutation(async (resourceType: ResourceTypeDto): Promise<ResourceTypeDto> => {
        if (resourceType.id) {
            return await request<ResourceTypeDto>({
                url: `/admin/resourceTypes/${resourceType.id}`,
                method: 'PUT',
                data: resourceType
            })
        } else {
            return await request<ResourceTypeDto>({
                url: `/admin/resourceTypes`,
                method: 'POST',
                data: resourceType
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_RESOURCE_TYPE_CACHE_KEY]);
        }
    })
}


export const useDeleteResourceType = () => {
    const queryClient = useQueryClient();

    return useMutation(async (resourceType: ResourceTypeDto) => {
            return await request({
                url: `/admin/resourceTypes/${resourceType.id}`,
                method: 'DELETE',
            })
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries([ADMIN_RESOURCE_TYPE_CACHE_KEY]);
            }
        }
    )
}
