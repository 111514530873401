import {
    Routes,
    Route, useLocation, Navigate
} from "react-router-dom";
import ErrorBoundary from "ErrorBoundary";
import {useEffect} from "react";
import RedirectIfLoggedIn from "../Components/RedirectIfLoggedIn";
import RequireAuthentication from "../Components/RequireAuthentication";
import "index.scss";
import styles from "./Routes.module.scss";
import AdminRoutes from "./Admin/AdminRoutes";
import OnboardingPage from "./(Client)/OnboardingPage";
import LoginPage from "./(Client)/LoginPage";
import ResetPasswordPage from "./(Client)/ResetPasswordPage";
import AuthActionsPage from "./(Client)/AuthActionsPage";
import VerifyEmailPage from "./(Client)/VerifyEmailPage";
import DashboardRoutes from "./(Client)/DashboardRoutes";

declare var gtag: any;

const ApplicationRoutes = () => {

    const location = useLocation();

    useEffect(() => {
        if (!window.location.hostname.includes('localhost')) {
            gtag('send', 'pageview');
            gtag('set', 'page', location.pathname);
        }
    }, [location.pathname])

    return (
        <div className={styles.container}>
            <ErrorBoundary>
                <Routes>
                    <Route path={"*"} element={(
                        <RedirectIfLoggedIn>
                            <Routes>
                                <Route path="/onboarding" element={<OnboardingPage />}/>
                                <Route path="/login" element={<LoginPage/>}/>
                                <Route path="/resetPassword" element={<ResetPasswordPage/>}/>
                                <Route path={"*"} element={<Navigate to={"/login"}/>}/>
                            </Routes>
                        </RedirectIfLoggedIn>
                    )}/>

                    <Route path="/authActions" element={<AuthActionsPage/>}/>

                    <Route path={"/verifyEmail"} element={(
                        <RequireAuthentication>
                            <VerifyEmailPage/>
                        </RequireAuthentication>
                    )}/>

                    <Route path={"/dashboard/*"} element={(
                        <RequireAuthentication>
                            <DashboardRoutes/>
                        </RequireAuthentication>
                    )}/>

                    <Route path="admin/*" element={<AdminRoutes />} />

                    <Route path={"/v2/*"} element={<Navigate to={location.pathname.replace("v2/", "")}/>}/>
                    <Route path={"*"} element={<Navigate to={"/login"}/>}/>
                </Routes>
            </ErrorBoundary>
        </div>
);
}



export default ApplicationRoutes;
