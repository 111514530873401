import styles from "../../../Pages/Admin/Terms/TermsPage.module.scss";
import FlexRow from "../../../Components/FlexRow";
import Button from "../../../Components/Button";
import Modal from "../../../Components/Modal";
import { useTranslation } from "react-i18next";
import {AgreementTermsModel} from "../../../Apis/Models/AgreementTermsModel";
import React, {useState} from "react";
import {AcceptedTermsModel} from "../../../Apis/Models/AgreementModel";
import Space from "Components/Space";

type Props = {
    terms?: AgreementTermsModel;
    acceptedTerms?: AcceptedTermsModel;
    button: React.ReactElement;
}
const ShowTermsModal = (props: Props) => {

    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(false)

    return (
        <>
            {React.cloneElement(props.button, {onClick: () => setVisible(true)})}
            <Modal title={t('terms:showTerms')} visible={visible} onCancel={() => setVisible(false)}>
                <Space className={"w-100"}>
                    <div className={styles.agreementArea}>
                        {props.acceptedTerms?.agreementText ?? props.terms?.text}
                    </div>

                    <FlexRow justify={"end"}>
                        <Button onClick={() => setVisible(false)} text={t('close')} />
                    </FlexRow>
                </Space>
            </Modal>
        </>
    )

}

export default ShowTermsModal
