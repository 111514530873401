import {AgreementModel} from "../../../Apis/Models/AgreementModel";
import {ReactElement, useEffect, useRef, useState} from "react";
import React from 'react';
import Modal from "../../../Components/Modal";
import {useTranslation} from "react-i18next";
import FlexRow from "Components/FlexRow";
import Grid from "Components/Grid";
import Button from "Components/Button";
import {AGREEMENT_ESTIMATE_CACHE_KEY, useAgreement} from "../../../Apis/AgreementApi";
import styles from './AgreementAddAuthorizationModal.module.scss';
import CheckmarkCircle from "../../../Assets/Icons/CheckmarkCircle";
import {StyledText} from "../../../Components/Text";
import {EloverblikAuthorizationUrl} from "../../../Apis/Models/EloverblikAuthorizationUrl";
import {useAuthorizationUrl} from "../../../Apis/EloverblikAuthorizationApi";
import Gap from "Components/Gap";
import Separator from "../../../Components/Separator";
import LightbulbIcon from "Assets/Icons/V2/LightbulbIcon";
import useToast from "Hooks/useToast";
import {useQueryClient} from "react-query";

type Props = {
    agreement: AgreementModel;
    trigger: ReactElement;
}
const AgreementAddAuthorizationModal = ({ agreement, trigger}: Props) => {

    const { t } = useTranslation();

    const [visible, setVisible] = useState<boolean>(false);
    const newWindowRef = useRef<any>(null);

    const { data: internalAgreement, refetch } = useAgreement(agreement.id);

    const [isWaitingForEloverblik, setIsWaitingForEloverblik ] = useState<boolean>(false);
    const [authorization, setAuthoriszation ] = useState<EloverblikAuthorizationUrl>();

    const [buttonState, setButtonState] = useState<'None' | 'Loading' | 'Success'>('None')

    const { mutateAsync: getAuthorizationUrlMutation } = useAuthorizationUrl();

    const { showSuccessToast } = useToast();

    const queryClient = useQueryClient();

    const accessGranted = () => {
        queryClient.invalidateQueries(AGREEMENT_ESTIMATE_CACHE_KEY)

        newWindowRef.current.close();

        setIsWaitingForEloverblik(false)
        setButtonState('Success');

        showSuccessToast(t('onboarding.eloverblik.success'));

        setTimeout(() => {
            setVisible(false)
        }, 2000)
    }

    useEffect(() => {
        if (!!internalAgreement && !!authorization && internalAgreement.eloverblikAuthorizations.find(x => x.id === authorization.id)?.status === 'Active') {
           accessGranted();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalAgreement, authorization]);

    useEffect(() => {
        if (isWaitingForEloverblik) {
            const interval = setInterval(() => {
                refetch();
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [isWaitingForEloverblik, refetch])

    const bulletPoint = (text: string) => (
        <div className={styles.explainerItem}>
            <CheckmarkCircle />
            <StyledText type={'Caption'} color={"secondary"}>
                {text}
            </StyledText>
        </div>
    )

    const giveAccess = async () => {
        setButtonState('Loading')

        const authorization = await getAuthorizationUrlMutation({ agreementId: agreement!.id });

        setAuthoriszation(authorization);

        newWindowRef.current = window.open(authorization.url);

        setIsWaitingForEloverblik(true);
    }

    return (
        <>
            {React.cloneElement(trigger, {onClick: () => setVisible(true)})}

            <Modal visible={visible} title={t('onboarding.addAuthorizationHeader')} onCancel={() => setVisible(false)}>
                <FlexRow justify={"center"}>
                    <Grid gridTemplateColumns={"1fr"} rowGap={16}>
                        <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                            {t('eloverblik.header')}
                        </StyledText>
                        <Gap size={"small"} direction={"vertical"}>

                            {bulletPoint(t('eloverblik.bullet.1'))}
                            {bulletPoint(t('eloverblik.bullet.2'))}
                            {bulletPoint(t('eloverblik.bullet.4'))}
                        </Gap>

                        <Separator/>

                        <FlexRow justify={"space-between"}>
                            <Gap size={"small"}>
                                <LightbulbIcon />
                                <StyledText type={"Caption"}>{t('eloverblik.openBlank')}</StyledText>
                            </Gap>

                            <Gap size={"small"}>
                                <Button text={buttonState !== 'None' ? t('onboarding.eloverblik.waiting') : t('onboarding.eloverblik.open')} onClick={giveAccess} state={buttonState} />
                            </Gap>
                        </FlexRow>
                    </Grid>
                </FlexRow>
            </Modal>
        </>
    )

}

export default AgreementAddAuthorizationModal
