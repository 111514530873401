import styles from "./Input.module.scss";
import {ChangeEvent, CSSProperties, ReactElement} from "react";
import {Controller} from "react-hook-form";

interface Props {
    name?: string;
    type?: 'text' | 'password' | 'number' | 'date';
    value?: string;
    defaultValue?: string | number;
    placeholder?: string;
    disabled?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void | undefined | Promise<any>;
    onBlur?: () => void;
    onFocus?: () => void;
    suffix?: ReactElement;
    style?: CSSProperties;
    className?: string;
    state?: 'default' | 'error' | 'success';
    min?: number,
    max?: number,
    maxLength?: number,
    step?: number,
}
const Input = (props: Props) => {

    return (
        <div className={`${styles.input} ${styles[props.state!]} ${props.disabled ? styles.disabled : ''} ${props.className ?? ''}`} style={props.style} >
            <input {...props} onChange={e => {
                if (props.maxLength && e.currentTarget.value.length > props.maxLength) {
                    return;
                }

                if (props.max && parseInt(e.currentTarget.value) > props.max) {
                    return;
                }

                if (props.min && parseInt(e.currentTarget.value) < props.min) {
                    return;
                }

                props.onChange && props.onChange(e);
            }} />
            {props.suffix && props.suffix}
        </div>
    )
}

Input.defaultProps = {
    state: 'default'
}

interface ControlledProps extends Props {
    name: string;
    control: any;
    rules?: any;
}
export const ControlledInput = (props: ControlledProps) => {

    return (
        <Controller name={props.name} rules={props.rules} defaultValue={props.defaultValue} control={props.control} render={({ field, fieldState}) => {
            return (
                <Input defaultValue={field.value} value={field.value ?? ""} {...props} onChange={(e) => {
                    field.onChange(e);
                    props.onChange && props.onChange(e);
                }} state={fieldState.error ? 'error' : "default"} />
            )
        }} />
    )

}

export default Input
