import { createContext, useCallback, useContext, useEffect, useState } from "react";
import * as React from "react";
import PageLoader from "Components/PageLoader";
import { useUserContext } from "Apis/UserContextApi";
import { UserContext } from "Apis/Models/UserContext";
import { User } from "firebase/auth";
import { forceRefreshUser, onAuthStateChanged } from "FirebaseSetup/auth";

type ContextProps = {
    firebaseUser: User | null,
    userContext: UserContext | null | undefined,
    forceReload: Function;
}
export const AuthContextProvider = createContext<ContextProps>({
    firebaseUser: null,
    userContext: undefined,
    forceReload: () => {
        throw new Error("Method not implemented")
    },
});
interface Props {
    children: React.ReactNode;
}
const AuthProvider = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [firebaseUser, setFirebaseUser] = useState<User | null>(null);

    const {data: userContext, refetch, isLoading: isLoadingUserContext, isFetched} = useUserContext(!!firebaseUser);

    useEffect(() => {
        onAuthStateChanged((user) => {
            setFirebaseUser(user);
            setLoading(false)
        })
    }, [])

    const forceReload = useCallback(async () => {
        await forceRefreshUser();

        await refetch();
    }, [refetch]);

    useEffect(() => {
        if (!!firebaseUser) {
            forceReload();
        }
    }, [firebaseUser, forceReload])

    return (
        <AuthContextProvider.Provider value={{firebaseUser, userContext, forceReload}}>
            <PageLoader shown={loading || (!isFetched && isLoadingUserContext)}>
                {props.children}
            </PageLoader>
        </AuthContextProvider.Provider>
    )
}

export default AuthProvider

export const useAuthProvider = () => {
    return useContext(AuthContextProvider);
}
