import {AgreementModel} from "../../../Apis/Models/AgreementModel";
import {ReactElement, useEffect, useState} from "react";
import React from 'react';
import Modal from "../../../Components/Modal";
import {useTranslation} from "react-i18next";
import FlexRow from "Components/FlexRow";
import Grid from "Components/Grid";
import Button from "Components/Button";
import {useUpdateAgreement} from "../../../Apis/AgreementApi";
import useToast from "../../../Hooks/useToast";
import {Button as RAButton, Calendar, CalendarCell, CalendarGrid, Heading, I18nProvider } from "react-aria-components";
import styles from './AgreementStartDateModal.module.scss'
import CaretIcon from "Assets/Icons/V2/CaretIcon";
import {parseDate, CalendarDate} from "@internationalized/date";
import moment from "moment";

type Props = {
    agreement: AgreementModel;
    trigger: ReactElement;
}
const AgreementStartDateModal = ({ agreement, trigger}: Props) => {

    const { t } = useTranslation();

    const [visible, setVisible] = useState<boolean>(false);

    const [dateValue, setDateValue] = useState<string>(agreement.expectedStartDate)

    const { mutateAsync: updateAgreement, isSuccess, isLoading } = useUpdateAgreement()

    const { showSuccessToast } = useToast();

    useEffect(() => {
        if (visible) {
            setDateValue(agreement.expectedStartDate)
        }
    }, [setDateValue, visible, agreement]);

    const onSubmit = async () => {
        await updateAgreement({...agreement, expectedStartDate: dateValue})

        showSuccessToast(t('onboarding.agreementStartDateUpdated'))

        setTimeout(() => {
            setVisible(false)
        }, 2000)
    }

    const dateValueChanged = (newDateValue: CalendarDate) => {
        setDateValue(moment(`${newDateValue.year}-${newDateValue.month}-${newDateValue.day}`).format("YYYY-MM-DD"));
    }

    return (
        <>
            {React.cloneElement(trigger, {onClick: () => setVisible(true)})}

            <Modal visible={visible} title={t('onboarding.editAgreementStartDate')} onCancel={() => setVisible(false)}>
                <FlexRow justify={"center"}>
                    <Grid gridTemplateColumns={"1fr"} rowGap={16}>
                        <I18nProvider locale="da-dk">
                            <Calendar aria-label={t('date')} pageBehavior={"single"} value={parseDate(dateValue)} onChange={dateValueChanged}>
                                <div className={styles.calendarHeader}>
                                    <RAButton type={"button"} className={styles.calenderButton} slot="previous"><CaretIcon style={{transform: 'rotate(90deg)'}} height={16} width={16} fill={"black"} /></RAButton>
                                    <Heading>
                                    </Heading>
                                    <RAButton type={"button"} className={`${styles.calenderButton} ${styles.next}`} slot="next"><CaretIcon style={{transform: 'rotate(-90deg)'}}  height={16} width={16} fill={"black"} /></RAButton>
                                </div>
                                <CalendarGrid className={styles.calendarCell}>
                                    {(date) => <CalendarCell className={styles.cell} date={date} />}
                                </CalendarGrid>
                            </Calendar>
                        </I18nProvider>

                        <FlexRow justify={"end"}>
                            <Button text={t('confirm')} onClick={onSubmit} state={isLoading ? 'Loading' : isSuccess ? 'Success' : 'None'} type={"submit"} />
                        </FlexRow>
                    </Grid>
                </FlexRow>
            </Modal>
        </>
    )

}

export default AgreementStartDateModal
