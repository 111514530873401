import styles from "./Box.module.scss";
import {CSSProperties} from "react";

interface Props {
    children: any;
    style?: CSSProperties;
    className?: string;
    size?: 'small'
    type?: 'booker' | 'info',
    noPadding?: boolean
}
const Box = (props: Props) => {

    return (
        <div className={`${styles.box} ${props.className} ${props.size && styles[props.size]} ${props.type && styles[props.type]} ${props.noPadding && styles.noPadding}`} style={props.style}>
            {props.children}
        </div>
    )

}

export default Box
