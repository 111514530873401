import styles from './InvoicesPage.module.scss'
import {useTranslation} from "react-i18next";
import FlexRow from "../../../Components/FlexRow";
import {StyledText} from "../../../Components/Text";
import Box from 'Components/Box';
import Table from 'Components/Table';
import moment from "moment/moment";
import ReportIcon from "../../../Assets/Icons/V2/ReportIcon";
import DownloadIcon from "../../../Assets/Icons/V2/DownloadIcon";
import {useAnnualReports} from "../../../Apis/SolarParkAnnualReportApi";
import {ISolarParkAnnualReportDto} from "../../../Apis/Models/ISolarParkAnnualReportDto";
import { saveAs } from 'file-saver';

const AnnualReportsPage = () => {

    const { t } = useTranslation();

    const { data: annualReports } = useAnnualReports();

    const downloadReport = async (report: ISolarParkAnnualReportDto) => {
        const response = await fetch(report.file.url);

        const blob = await response.blob();

        saveAs(blob, report.file.fileName);
    }

    return (
        <Box className={styles.container}>
            <FlexRow justify={"space-between"}>
                <StyledText type={"Lead"} weight={"Bold"} color={"secondary"}>{t('annualReports')}</StyledText>
            </FlexRow>

            <Table>
                <thead>
                    <tr>
                        <th>
                            {t('period')}
                        </th>
                        <th>
                            {t('download')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                {annualReports?.map(annualReport => (
                    <tr key={annualReport.id}>
                        <td>
                            <div className={"flex align-center"} style={{gap: 8}}>
                                <ReportIcon fill={"#B386CF"}/>
                                {t('yearlyReport')} {moment(new Date()).format('YYYY')}
                            </div>
                        </td>
                        <td>
                            <div className={"flex align-center"} style={{gap: 8}} onClick={() => downloadReport(annualReport)}>
                                <DownloadIcon />
                                {t('downloadYearlyReport')}
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Box>
    )

}

export default AnnualReportsPage
