const DownloadCircleIcon = () => {

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.000244141" width="40" height="40" rx="20" fill="#9E9E9E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.3349 20.041C19.3341 20.0274 19.3337 20.0138 19.3337 20V14.6667C19.3337 14.2985 19.6321 14 20.0003 14C20.3685 14 20.667 14.2985 20.667 14.6667V20C20.667 20.0018 20.667 20.0037 20.667 20.0055L22.268 18.8C22.5625 18.5791 22.9804 18.6388 23.2013 18.9334C23.4222 19.2279 23.3625 19.6458 23.068 19.8667L20.4013 21.8667C20.2859 21.9533 20.1456 22 20.0013 22C19.8631 22.0011 19.728 21.9592 19.6147 21.88L16.948 20C16.6488 19.7878 16.5773 19.3737 16.788 19.0734C16.9993 18.7718 17.415 18.6987 17.7165 18.91L17.7213 18.9134L19.3349 20.041ZM24.0003 24H16.0003V23.3333C16.0003 22.9651 15.7018 22.6667 15.3337 22.6667C14.9655 22.6667 14.667 22.9651 14.667 23.3333V24.6667C14.667 25.0349 14.9655 25.3333 15.3337 25.3333H24.667C24.713 25.3333 24.7579 25.3287 24.8013 25.3198C25.1051 25.2576 25.3337 24.9888 25.3337 24.6667V23.3333C25.3337 22.9651 25.0352 22.6667 24.667 22.6667C24.2988 22.6667 24.0003 22.9651 24.0003 23.3333V24Z" fill="white"/>
        </svg>

    )

}

export default DownloadCircleIcon
