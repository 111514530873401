import FlexRow from "../../../Components/FlexRow";
import Gap from "../../../Components/Gap";
import {StyledText} from "../../../Components/Text";
import moment from "moment/moment";
import {PopupButton, useCalendlyEventListener} from "react-calendly";
import config from "../../../Config";
import Box from "../../../Components/Box";
import React, {useEffect, useState} from "react";
import {useCalendylyItem} from "../../../Apis/CalendlyApi";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";

const CALENDLY_EVENT_SCHEDULED = 'CALENDLY_EVENT_SCHEDULED';

const CalendlyBooker = () => {

    const { t } = useTranslation();

    const [calendlyEventUri, setCalendlyEventUri] = useState<string>('');
    const [searchParams ] = useSearchParams();

    const { mutateAsync: getCalendlyItem } = useCalendylyItem();
    const [calendlyItem, setCalendlyItem] = useState<any>(null)

     useCalendlyEventListener({
        onEventScheduled: (e) => {
            localStorage.setItem(CALENDLY_EVENT_SCHEDULED, e.data.payload.event.uri);
            setCalendlyEventUri(e.data.payload.event.uri);
        }
    });

    useEffect(() => {
        let savedUri = localStorage.getItem(CALENDLY_EVENT_SCHEDULED);
        setCalendlyEventUri(savedUri ?? '');

        if (!!savedUri) {
            getCalendlyItem(savedUri).then(e => {
                if (e.data.resource.status !== "active") {
                    localStorage.removeItem(CALENDLY_EVENT_SCHEDULED)
                    setCalendlyEventUri('')
                } else {
                    setCalendlyItem(e);
                }
            });
        }
    }, [getCalendlyItem, setCalendlyItem, calendlyEventUri]);


    return (
        <Box type={"booker"} size={"small"} style={{width: '100%'}}>
            <FlexRow justify={"space-between"}>
                <Gap size={"medium"}>
                    <img src="/Images/Resources/thomas.png" alt="Thomas" />
                    <FlexRow direction={"column"} align={"start"} style={{maxWidth: 220}}>
                        <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                            {!!calendlyItem && (
                                t('youHaveAConversation')
                            )}
                            {!calendlyItem && (
                                t('weAreHereToHelp')
                            )}
                        </StyledText>
                        <StyledText type={"Caption"}>
                            {!!calendlyItem && (
                                <>
                                    {t('lookingForwardToMeetingYou')} <span style={{textDecoration: 'underline', fontWeight: 'bold'}}>{moment(calendlyItem.data.resource.start_time).format('dddd [d.] DD.MM [kl.] hh:mm')}</span>
                                </>
                            )}
                            {!calendlyItem  && t('weAreHereToHelpSub')}
                        </StyledText>
                    </FlexRow>
                </Gap>
                <PopupButton
                    url={config.calendly.url}
                    rootElement={document.getElementById("root")!}
                    text={!!calendlyEventUri ? t('meetingBooked') : t('bookConversation')}
                    className={"calendlyOutlineButton"}
                    prefill={{
                        name: searchParams?.get('companyName') ?? '',
                        email: searchParams?.get('email') ?? '',
                        firstName: searchParams?.get('firstName') ?? '',
                        lastName: searchParams?.get('lastName') ?? '',
                        smsReminderNumber: searchParams?.get('phoneNumber') ?? '',
                    }}
                />
            </FlexRow>
        </Box>
    )

}

export default CalendlyBooker
