import {useAgreements} from "../../../Apis/AgreementApi";
import Dropdown from "../../../Components/Dropdown";
import DropdownItem from "../../../Components/DropdownItem";
import {useTranslation} from "react-i18next";
import {StyledText} from "../../../Components/Text";
import CaretIcon from "../../../Assets/Icons/V2/CaretIcon";
import FlexRow from "../../../Components/FlexRow";
import {useSearchParams} from "react-router-dom";
import useSelectedAgreement, {AGREEMENT_QUERY_PARAM} from "../Hooks/useSelectedAgreement";
import {useEffect} from "react";
import {AgreementModel} from "../../../Apis/Models/AgreementModel";
import CheckmarkCircle from "../../../Assets/Icons/CheckmarkCircle";

const AgreementSelector = ({requireSelect = false, position = undefined, onlySigned = false} : {requireSelect?: boolean, position?: 'Under Right' | 'Under Left' | 'Right', onlySigned?: boolean}) => {

    const { t } = useTranslation();

    const [ searchParams, setSearchParams ] = useSearchParams();

    const { data: agreements } = useAgreements(onlySigned);

    const agreement = useSelectedAgreement();

    useEffect(() => {
        if (!searchParams.has(AGREEMENT_QUERY_PARAM) && agreements?.length === 1) {
            setSearchParams({...searchParams, [AGREEMENT_QUERY_PARAM]: agreements[0].id})
        }
    }, [searchParams, setSearchParams, agreements]);

    const removeAgreementParam = () => {
        searchParams.delete(AGREEMENT_QUERY_PARAM);

        setSearchParams(searchParams)
    }

    const selectAgreement = (agreement: AgreementModel) => setSearchParams({...searchParams, [AGREEMENT_QUERY_PARAM]: agreement.id})

    return (
        <div>
            <Dropdown position={position} toggle={
                <FlexRow>
                    <StyledText type={"Caption"}>{requireSelect && !agreement ? t('selectAnAgreement') : (<>{t('shows')} <StyledText type={"Caption"} weight={"Bold"}>{agreement?.nickname ?? t('all')}</StyledText></>)}</StyledText>
                    <CaretIcon />
                </FlexRow>}
            >
                <>
                    {!requireSelect && !!agreements && agreements?.length > 1 && (
                        <DropdownItem onClick={removeAgreementParam}>
                            {t('all')}
                        </DropdownItem>
                    )}
                    {agreements?.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(x => (
                        <DropdownItem onClick={() => selectAgreement(x)} key={x.id}>
                            <FlexRow justify={"space-between"}>
                                <span>
                                    {x.nickname}
                                </span>
                                {agreement?.id === x.id && <CheckmarkCircle />}
                            </FlexRow>
                        </DropdownItem>
                    ))}
                </>
            </Dropdown>
        </div>
    )

}

export default AgreementSelector
