import styles from "./Textarea.module.scss";
import React from "react";
import { Controller } from 'react-hook-form';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    label?: string;
    name: string;
    errors?: any;
    rules?: any;
    control: any;
    rows?: number
}
const Textarea = (props: Props) => {

    return (
        <Controller
            name={props.name}
            rules={props.rules}
            control={props.control}
            defaultValue={props.defaultValue ?? ''}
            render={(
                { field },
            ) => (
                <>
                    <textarea
                        {...field}
                        onChange={e => {
                            field.onChange(e);
                            if (props.onChange) {
                                props.onChange(e)
                            }
                        }}
                        className={`${styles.input} ${props.errors && styles.errors}`}
                        placeholder={props.placeholder}
                        rows={props.rows}
                    />
                </>
            )}
        />
    )
}

Textarea.defaultProps = {
    rows: 5
}

export default Textarea
