import DefaultPageLayout from "../../Layouts/DefaultPageLayout";
import React, {useEffect, useRef, useState} from "react";
import styles from './LoginPage.module.scss';
import {StyledText} from "../../Components/Text";
import Box from "Components/Box";
import InputLabel from "../../Components/InputLabel";
import {ControlledInput} from "../../Components/Input";
import Gap from "../../Components/Gap";
import Button from "../../Components/Button";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {signIn} from "../../FirebaseSetup/auth";
import useToast from "Hooks/useToast";
import {Link, useNavigate} from "react-router-dom";
import OnboardingBoxContent from "../../Features/Onboarding/Components/OnboardingBoxContent";
import FlexRow from "Components/FlexRow";
import {trackClick} from "../../Utils/GAUtils";

const LoginPage = () => {

    const navigate = useNavigate();
    const { showErrorToast } = useToast();

    const [loginTries, setLoginTries] = useState<number>(0);

    const pageLoadTime = useRef(new Date().getTime());

    useEffect(() => {
        const controller = new AbortController();

        window.addEventListener('beforeunload', () => {
            if (loginTries > 0) {
                trackClick('login_dropoff', {loginTries})
            }
        }, { signal: controller.signal });

        return () => {
            controller.abort();
        }
    }, [loginTries]);

    const login = async (data: any) => {
        setLoading(true);

        const timeDiff = Math.round(((new Date().getTime()) - pageLoadTime.current) / 1000);

        trackClick('login_attempt', {timeToLogin: timeDiff, loginTries})

        try {
            await signIn(data.email, data.password);
            trackClick('login_success', {loginTries, timeToLogin: timeDiff})
        } catch (e: any) {
            let message = t('somethingWentWrong');

            if (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password') {
                message = t('auth:invalidCredentials');
            }
            if (e.code === 'auth/invalid-email') {
                message = t('auth:invalidEmail');
            }

            trackClick('login_failed', {error: e.code, loginTries, timeToLogin: timeDiff})

            showErrorToast(message);
        } finally {
            setLoginTries(c => c + 1)
            setLoading(false);
        }
    }

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false)

    const {control, handleSubmit} = useForm();

    return (
        <DefaultPageLayout contentMaxWidth={1440}>
            <div className={styles.container}>

                <div className={styles.headline}>
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {t('signIn')}
                    </StyledText>
                </div>

                <Box style={{width: 840}}>
                    <form onSubmit={handleSubmit(login)} className={styles.content}>
                        <OnboardingBoxContent content={(
                            <>
                                <div>
                                    <InputLabel required>
                                        {t('email')}
                                    </InputLabel>
                                    <ControlledInput name={'email'} placeholder={t('yourEmail')} control={control} rules={{required: true}}/>
                                </div>

                                <div>
                                    <InputLabel required>
                                        {t('password')}
                                    </InputLabel>
                                    <ControlledInput name={'password'} placeholder={t('yourPassword')} type={"password"} control={control} rules={{required: true}}/>
                                </div>
                            </>
                        )} buttons={(
                            <Gap size={"medium"} className={"w-100"} direction={"vertical"}>
                                <FlexRow justify={"space-between"}>
                                    <Button text={t('auth:login.forgotPassword')} color={"primary"} outline type={"button"} onClick={() => navigate('/resetPassword')} />
                                    <Button text={t('signIn')} type={"submit"} state={loading ? "Loading" : "None"}/>
                                </FlexRow>
                                <Link style={{fontWeight: "bold", color: '#575757'}} to={"/onboarding"}>{t('auth:dontHaveAccount')}</Link>
                            </Gap>
                        )} />
                    </form>
                </Box>
            </div>
        </DefaultPageLayout>
    )
}

export default LoginPage
