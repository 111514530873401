import {useMutation, useQuery, useQueryClient} from "react-query";
import {request} from "../BaseApi";
import {ResourceDto} from "../Models/ResourceDto";
import {AgreementModel} from "../Models/AgreementModel";

export const ADMIN_RESOURCES_CACHE_KEY = "ADMIN_RESOURCES_CACHE_KEY";
export const useResources = (selectedAgreement: AgreementModel | undefined | null) => {
    return useQuery([ADMIN_RESOURCES_CACHE_KEY, selectedAgreement?.id], async () => {
        return await request<ResourceDto[]>({
            url: `/admin/resources` + (!!selectedAgreement ? `?agreementId=${selectedAgreement?.id}` : ''),
            method: 'GET',
        })
    })
}

export const useSaveResource = () => {

    const queryClient = useQueryClient();

    return useMutation(async (resourceType: ResourceDto): Promise<ResourceDto> => {
        if (resourceType.id) {
            return await request<ResourceDto>({
                url: `/admin/resources/${resourceType.id}`,
                method: 'PUT',
                data: resourceType
            })
        } else {
            return await request<ResourceDto>({
                url: `/admin/resources`,
                method: 'POST',
                data: resourceType
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_RESOURCES_CACHE_KEY]);
        }
    })
}


export const useDeleteResource = () => {
    const queryClient = useQueryClient();

    return useMutation(async (resource: ResourceDto) => {
            return await request({
                url: `/admin/resources/${resource.id}`,
                method: 'DELETE',
            })
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries([ADMIN_RESOURCES_CACHE_KEY]);
            }
        }
    )
}
