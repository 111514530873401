import { Navigate, Route, Routes } from "react-router-dom";
import AgreementRoutes from "Pages/Admin/Agreements/AgreementRoutes";
import { useAuthProvider } from "Providers/AuthProvider";
import TermsRoute from "Pages/Admin/Terms/TermsRoute";
import { useTranslation } from "react-i18next";
import SolarParksPage from "Pages/Admin/SolarPark/SolarParksPage";
import BIPage from "Pages/Admin/BI/BIPage";
import AnnualReportsPage from "./AnnualReports/AnnualReportsPage";
import InvoicesRoutes from "./Invoices/InvoicesRoutes";
import moment from "moment/moment";
import DefaultPageLayout from "../../Layouts/DefaultPageLayout";
import NavigationItem from "../../Layouts/NavigationItem";
import Grid from "../../Components/Grid";
import ResourceTypesPage from "./Resources/ResourceTypesPage";
import ResourcesPage from "./Resources/ResourcesPage";

const AdminRoutes = () => {

    const { t } = useTranslation();

    const { userContext } = useAuthProvider();

    if (!userContext) {
        return <></>
    }

    if (!userContext.permissions.includes("Admin")) {
        return <Navigate to={"/"} />
    }

    const adminPage = (content: any) => (
        <DefaultPageLayout contentMaxWidth={undefined} menuContent={(
            <>
                <NavigationItem text={t('agreement:title')} icon={<></>} route={`/admin/agreements`} />
                <NavigationItem text={t('invoices')} icon={<></>} isExact route={`/admin/invoices`} />
                <NavigationItem text={t('invoiceable')} icon={<></>} route={`/admin/invoices/invoiceable?until=${moment().startOf("month").add(-1, 'day').format("YYYY-MM-DD")}`} />
                <NavigationItem text={t('terms:terms')} icon={<></>}  route={`/admin/terms`} />
                <NavigationItem text={t('solarPark:title')} icon={<></>}  route={`/admin/solarparks`} />
                <NavigationItem text={t('annualReport.annualReports')} icon={<></>}  route={`/admin/annualReports`} />
                <NavigationItem text={t('resources.title')} icon={<></>}  route={`/admin/resources`} />
                <NavigationItem text={t('bi.bi')} icon={<></>}  route={`/admin/bi`} />
            </>
        )}>
            <Grid gridTemplateColumns={"1fr"} rowGap={48}>
                {content}
            </Grid>
        </DefaultPageLayout>
    )

    return (
        <Routes>
            <Route path="agreements" element={adminPage(<AgreementRoutes />)} />

            <Route path={"/agreements/*"} element={adminPage(<AgreementRoutes />)}/>

            <Route path={"/invoices/*"} element={adminPage(<InvoicesRoutes />)}/>

            <Route path={"/solarParks"} element={adminPage(<SolarParksPage />)}/>

            <Route path={"/bi"} element={adminPage(<BIPage />)}/>

            <Route path={"/terms/*"} element={adminPage(<TermsRoute />)}/>

            <Route path={"/annualReports"} element={adminPage(<AnnualReportsPage />)}/>

            <Route path={"/resourceTypes"} element={adminPage(<ResourceTypesPage />)}/>
            <Route path={"/resources"} element={adminPage(<ResourcesPage />)}/>

            <Route path={"*"} element={<Navigate to={"/admin/agreements"}/>}/>
        </Routes>
    )
}

export default AdminRoutes
