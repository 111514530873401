import React from "react";

const LinkCircleIcon = () => {

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#9E9E9E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.2 10C16.8418 10 17.6 11.1664 17.6 14.9231C17.6 16.635 17.0962 18.5704 16.0997 20.7638L15.984 21.0117L16.1046 21.2829L16.24 21.5692L16.5301 22.1549L16.7702 22.6141L16.9064 22.8539L17.026 23.0431C17.2876 23.4276 17.4329 23.4189 17.5918 23.1485L17.6611 23.017L17.7344 22.8557L19.3344 19.1634C19.7456 18.2145 20.702 18.562 20.7938 19.6937C20.9991 22.2226 21.4739 23.3588 22.2104 23.5186L22.292 23.5311L22.3797 23.4512L22.5089 23.3192L22.7586 23.0415L23.7281 21.8853L23.891 21.6983C24.3042 21.2341 24.4784 21.0769 24.8 21.0769C25.3988 21.0769 26.1333 21.7426 27.1829 23.111L27.5447 23.5932C27.607 23.6779 27.6704 23.7648 27.7348 23.8539C28.0634 24.3083 28.0903 25.0865 27.795 25.592C27.4996 26.0975 26.9938 26.139 26.6652 25.6846L26.3018 25.192C26.1275 24.9608 25.9643 24.7524 25.8124 24.5668L25.524 24.2264C25.433 24.1231 25.3471 24.03 25.2664 23.9472L25.096 23.7806L24.9016 23.6123L24.6099 23.9469L23.7684 24.9551L23.5149 25.2474C23.4758 25.2914 23.4382 25.333 23.4019 25.3724L23.1987 25.5833C22.8823 25.8929 22.6672 26 22.4 26C21.1299 26 20.2296 24.9427 19.6955 23.007L19.656 22.8578L19.0656 24.2212C17.9223 26.8597 16.5744 26.2492 15.0755 23.2791L14.9496 23.024L14.7982 23.2949C14.4408 23.9234 14.0495 24.5706 13.6247 25.2372L13.3657 25.6395C13.0533 26.1202 12.5467 26.1202 12.2343 25.6395C11.9219 25.1589 11.9219 24.3796 12.2343 23.8989C12.8866 22.8954 13.453 21.9479 13.9344 21.0593L14.0472 20.848L13.9663 20.6198C13.3541 18.8518 12.8843 16.8793 12.8099 15.2803L12.8001 14.9436C12.7697 12.1466 13.8054 10 15.2 10ZM17.5556 12.7778C16.8842 12.7778 16.1373 13.7841 16.1665 15.5325C16.1834 16.5511 16.597 17.9236 17.2038 19.3021L17.3944 19.7208L17.4246 19.6743C18.3734 18.1577 18.8751 16.8544 18.9377 15.7819L18.9444 15.5556L18.9405 14.7736L18.9308 14.3436L18.9125 13.9803C18.846 13.0399 18.6449 12.834 18.0469 12.7897L17.819 12.7798L17.5556 12.7778Z" fill="white"/>
        </svg>
    )

}

export default LinkCircleIcon
