import React, { CSSProperties, useEffect } from "react";
import { createPortal } from "react-dom";
import styles from './Modal.module.scss';
import { CSSTransition } from "react-transition-group";
import CloseIcon from "Assets/Icons/CloseIcon";
import {StyledText} from "./Text";

interface Props {
    visible: boolean;
    onCancel?: () => void;
    title?: string;
    children?: any;
    modalStyle?: CSSProperties;
    closeOnOutsideClick?: boolean;
}
const Modal = ({ visible, onCancel, title, children, modalStyle, closeOnOutsideClick }: Props) => {

    useEffect(() => {
        if (closeOnOutsideClick) {
            const closeOnEscapeKeyDown = e => {
                if ((e.charCode || e.keyCode) === 27) {
                    if (onCancel) {
                        onCancel()
                    }
                }
            };

            document.body.addEventListener("keydown", closeOnEscapeKeyDown);

            return () => {
                document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
            }
        }
    }, [onCancel, closeOnOutsideClick]);

    return createPortal(
        <CSSTransition
            in={visible}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
            classNames={{
                enterDone: styles.enterDone,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive
            }}
        >
            <div className={styles.modal} onMouseDown={() => {
                if (closeOnOutsideClick && onCancel) {
                    onCancel()
                }
            }}>
                <div className={styles.content} onMouseDown={e => e.stopPropagation()} style={modalStyle}>
                    <div onClick={onCancel} className={styles.close}>
                        <CloseIcon />
                    </div>
                    <div className={styles.header}>
                        <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                            {title}
                        </StyledText>
                    </div>
                    <div className={styles.body}>
                        {children}
                    </div>
                </div>
            </div>
        </CSSTransition>
        , document.getElementById('root')!)
}

Modal.defaultProps = {
    closeOnOutsideClick: true
}

export default Modal
