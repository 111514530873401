import FlexRow from "Components/FlexRow";
import List from "Components/List";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useSaveSolarPark, useSolarParks } from "Apis/Admin/SolarParkAdminApi";
import { useForm } from "react-hook-form";
import { ISolarParkDto } from "Apis/Models/ISolarPark";
import {ControlledInput} from "Components/Input";
import Space from "Components/Space";
import { FileDto } from "Apis/Models/FileDto";
import styles from './SolarParksPage.module.scss';
import FileUploadButton from "Components/FileUploadButton";
import CloseIcon from "Assets/Icons/CloseIcon";
import Button from "../../../Components/Button";
import Box from "Components/Box";
import {StyledText} from "../../../Components/Text";
import Modal from "Components/Modal";
import InputLabel from "../../../Components/InputLabel";

const SolarParksPage = () => {

    const { t } = useTranslation();

    const { data, isLoading } = useSolarParks();
    const { mutateAsync: saveSolarParkMutation, isLoading: isSavingPark } = useSaveSolarPark();

    const [solarParkModalVisible, setSolarParkModalVisible] = useState<boolean>(false);

    const { handleSubmit, register, control, reset } = useForm<ISolarParkDto>();
    const [parkImages, setParkImages] = useState<FileDto[]>([]);

    const selectPark = (park: ISolarParkDto) => {
        setSolarParkModalVisible(true);

        setParkImages(park.images || []);

        reset(park);
    }

    const savePark = async (data: ISolarParkDto) => {
        await saveSolarParkMutation({ ...data, images: parkImages });

        closeModal();
    }

    const closeModal = () => {
        setSolarParkModalVisible(false);
        reset({
            id: '',
            name: '',
        });
        setParkImages([])
    }

    const removeImage = (image: FileDto) => {
        setParkImages(current => current.filter(i => i.id !== image.id));
    }

    return (
        <>

            <Box>
                <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                    <StyledText type={"Lead"} weight={"Bold"} color={"secondary"}>{t('solarPark:title')}</StyledText>

                    <Button color={"link"} onClick={() => setSolarParkModalVisible(true)} text={`+ ${t('solarPark:createSolarPark')}`} />
                </FlexRow>

                <List keyDataIndex={"id"} loading={isLoading} data={data} emptyText={t('solarPark:noParksFound')}  columns={[
                    {
                        dataIndex: "name",
                        title: t('solarPark:name'),
                    },
                    {
                        dataIndex: "images",
                        title: t('solarPark:imageCount'),
                        textRender: (_, park: ISolarParkDto) => park.images?.length.toString()
                    },
                    {
                        title: t('solarPark:functions'),
                        customRender: (_, park: ISolarParkDto) => (
                            <Button color={"link"} onClick={() => selectPark(park)} text={t('solarPark:select')} />
                        )
                    },
                ]} />
            </Box>

            <Modal closeOnOutsideClick={false} title={t('solarPark:createNewSolarPark')} visible={solarParkModalVisible} onCancel={closeModal}>
                <form onSubmit={handleSubmit(savePark)}>
                    <input type={"hidden"} {...register('id')} />
                    <Space direction={"column"} size={"large"}>
                        <div className={"w-100"}>
                            <InputLabel>
                                {t('solarPark:name')}
                            </InputLabel>
                            <ControlledInput placeholder={t('solarPark:name')} name={"name"} control={control} />
                        </div>
                        {parkImages.map((image, index) => (
                            <div className={styles.image}>
                                <div onClick={() => removeImage(image)} className={styles.remove}>
                                    <CloseIcon fill={"red"} />
                                </div>
                                <img alt={image.fileName} width={170} height={"auto"} src={image.url} />
                            </div>
                        ))}

                        <FlexRow>
                            <FileUploadButton multiple callback={file => setParkImages(current => [...current, file])} />
                        </FlexRow>

                        <FlexRow justify={"end"}>
                            <Button state={isSavingPark ? 'Loading' : 'None'} type={"submit"} text={t('save')} />
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </>
    )
}

export default SolarParksPage
