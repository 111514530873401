import {useMutation, useQuery, useQueryClient} from "react-query";
import {request} from "./BaseApi";
import {ResourceDto} from "./Models/ResourceDto";
import {AgreementModel} from "./Models/AgreementModel";
import {ResourceAgreementDto} from "./Models/ResourceAgreementDto";

export const RESOURCES_CACHE_KEY = "RESOURCES_CACHE_KEY";
export const useResources = (selectedAgreement: AgreementModel | undefined | null) => {
    return useQuery([RESOURCES_CACHE_KEY, selectedAgreement?.id], async () => {
        return await request<ResourceAgreementDto[]>({
            url: `/resources/${selectedAgreement?.id}`,
            method: 'GET',
        })
    }, {
        enabled: !!selectedAgreement
    })
}

export const useSaveStatus = () => {

    const queryClient = useQueryClient();

    return useMutation(async (model: { resourceId: string, agreementId: string}) => {
        return await request<ResourceDto>({
            url: `/resources/${model.agreementId}/${model.resourceId}`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries([RESOURCES_CACHE_KEY]);
        }
    })
}
