import { Navigate, useLocation } from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";

const RequireAuthentication = ({ children }) => {

    let location = useLocation();

    const user = useAuthProvider();

    if (!user?.firebaseUser && !location.pathname.includes("/login")) {
        return <Navigate to={"/login"}/>
    }

    if (!user.firebaseUser?.emailVerified && !location.pathname.includes("/verifyEmail") && !location.pathname.includes("/authActions")) {
        return (
            <Navigate to={"/verifyEmail"}/>
        )
    }

    return children;
}

export default RequireAuthentication;
