import {useAgreements, useDeleteAgreement, useSaveAgreement} from "Apis/Admin/AgreementAdminApi";
import List from "Components/List";
import {Trans, useTranslation} from "react-i18next";
import FlexRow from "Components/FlexRow";
import Modal from "Components/Modal";
import React, { useState } from "react";
import Space from "Components/Space";
import { useForm } from "react-hook-form";
import { AgreementModel, AgreementType } from "Apis/Models/AgreementModel";
import { useNavigate } from "react-router-dom";
import Box from "../../../Components/Box";
import {StyledText} from "../../../Components/Text";
import Button from "Components/Button";
import {ControlledInput} from "../../../Components/Input";
import InputLabel from "../../../Components/InputLabel";

const ListAgreementsPage = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { data, isLoading } = useAgreements();

    const [createAgreementModalVisible, setCreateAgreementModalVisible] = useState<boolean>(false);

    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [selectedAgreement, setSelectedAgreement] = useState<AgreementModel>();

    const { mutateAsync: createAgreementMutation, isLoading: isCreatingNewAgreement } = useSaveAgreement();
    const { mutateAsync: deleteAgreementMutation, isLoading: isDeletingAgreement } = useDeleteAgreement();

    const { control, handleSubmit } = useForm<AgreementModel>();

    const createNewAgreement = async (data: AgreementModel) => {
        const response = await createAgreementMutation(data);

        navigate(`/admin/agreements/${response.id}`)
    }

    const getAgreementTypeText = (agreement: AgreementType) => {
        switch (agreement) {
            case "Custom":
                return t('agreement:custom');
            case "Match":
                return t('agreement:match');
            case "MatchAndCustom":
                return t('agreement:matchAndCustom');
        }
    }

    const getAgreementStatus = (agreement: AgreementModel) => {
        if (!agreement.agreementSignedAt) {
            return t('agreement:notSigned');
        }

        return t('agreement:signed');
    }

    const selectAgreementForDeletion = (agreement: AgreementModel) => {
        setSelectedAgreement(agreement);
        setDeleteModalVisible(true)
    }

    const cancelDelete = () => {
        setDeleteModalVisible(false);
        setSelectedAgreement(undefined);
    }

    const deleteAgreement = async () => {
        if (!selectedAgreement) {
            return
        }

        await deleteAgreementMutation(selectedAgreement);

        setSelectedAgreement(undefined);

        setDeleteModalVisible(false);
    }


    return (
        <Box>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <StyledText type={"Lead"} weight={"Bold"} color={"secondary"}>{t('agreement:title')}</StyledText>

                <Button color={"link"} onClick={() => setCreateAgreementModalVisible(true)} text={`+ ${t('agreement:createNewAgreement')}`} />
            </FlexRow>

            <List keyDataIndex={"id"} loading={isLoading} data={data} emptyText={t('terms:noAgreementsFound')}  columns={[
                {
                    dataIndex: "companyName",
                    title: t('agreement:company'),
                },
                {
                    dataIndex: "nickname",
                    title: t('nickname'),
                },
                {
                    dataIndex: "vatNumber",
                    title: t('agreement:vatNumber'),
                },
                {
                    dataIndex: "agreementType",
                    title: t('agreement:type'),
                    textRender: (text) => getAgreementTypeText(text),
                },
                {
                    dataIndex: "id",
                    title: t('agreement:status.title'),
                    textRender: (_, record) => getAgreementStatus(record),
                },
                {
                    dataIndex: "id",
                    title: t('agreement:functions'),
                    customRender: (_, record) => (
                        <Space direction={"row"} size={"large"}>
                            <Button color={"link"} onClick={() => navigate(`/admin/agreements/${record.id}`)} text={`${t('agreement:goto')}`} />
                            <Button color={"link"} onClick={() => selectAgreementForDeletion(record)} text={`${t('agreement:delete')}`} />
                        </Space>
                    )
                },
            ]} />

            <Modal title={t('agreement:createNewAgreement')} visible={createAgreementModalVisible} onCancel={() => setCreateAgreementModalVisible(false)}>
                <form onSubmit={handleSubmit(createNewAgreement)}>
                    <Space>
                        <div className={"w-100"}>
                            <InputLabel required>
                                {t('companyName')}
                            </InputLabel>
                            <ControlledInput name={"companyName"} control={control} rules={{required: true}} placeholder={t('companyName')} />
                        </div>

                        <div className={"w-100"}>
                            <InputLabel required>
                                {t('nickname')}
                            </InputLabel>
                            <ControlledInput name={"nickname"} control={control} placeholder={t('nickname')} />
                        </div>

                        <div className={"w-100"}>
                            <InputLabel required>
                                {t('vatNumber')}
                            </InputLabel>
                            <ControlledInput name={"vatNumber"} control={control} placeholder={t('vatNumber')} rules={{required: true}}/>
                        </div>

                        <div className={"w-100"}>
                            <InputLabel required>
                                {t('contactPerson')}
                            </InputLabel>
                            <ControlledInput name={"name"} control={control} placeholder={t('contactPerson')} rules={{required: true}}  />
                        </div>

                        <div className={"w-100"}>
                            <InputLabel required>
                                {t('address')}
                            </InputLabel>
                            <ControlledInput name={"address"} control={control} placeholder={t('address')} rules={{required: true}}/>
                        </div>

                        <div className={"w-100"}>
                            <InputLabel required>
                                {t('zipCode')}
                            </InputLabel>
                            <ControlledInput name={"zipCode"} control={control} placeholder={t('zipCode')} rules={{required: true}}/>
                        </div>

                        <div className={"w-100"}>
                            <InputLabel required>
                                {t('city')}
                            </InputLabel>
                            <ControlledInput name={"city"} control={control} placeholder={t('city')} rules={{required: true}} />
                        </div>


                        <FlexRow justify={"end"}>
                            <Button disabled={isCreatingNewAgreement} type={"submit"} state={isCreatingNewAgreement ? 'Loading' : 'None'} text={t('create')} />
                        </FlexRow>
                    </Space>
                </form>
            </Modal>

            <Modal title={t('agreement:deleteAgreement')} visible={deleteModalVisible} onCancel={() => cancelDelete()}>
                <Space>
                <div className={"w-100"}>
                        <Trans i18nKey="agreement:deleteAgreementConfirmation" values={{company: selectedAgreement?.companyName, vatNumber: selectedAgreement?.vatNumber}} />
                    </div>

                    <FlexRow justify={"end"}>
                        <Button color={"danger"}  disabled={isDeletingAgreement} onClick={deleteAgreement} state={isDeletingAgreement ? 'Loading' : 'None'} text={t('delete')} />
                    </FlexRow>
                </Space>
            </Modal>
        </Box>
    )
}

export default ListAgreementsPage
