import {StyledText} from "../../../Components/Text";
import React from "react";
import Box from "Components/Box";
import {useTranslation} from "react-i18next";
import AgreementSelector from "../../../Features/Dashboard/Components/AgreementSelector";
import useSelectedAgreement from "../../../Features/Dashboard/Hooks/useSelectedAgreement";
import styles from './ResourcesPage.module.scss'
import Gap from "Components/Gap";
import FlexRow from "../../../Components/FlexRow";
import CalendlyBooker from "../../../Features/Onboarding/Components/CalendlyBooker";
import Grid from "../../../Components/Grid";
import Button from "../../../Components/Button";
import {useNavigate} from "react-router";
import {useResources, useSaveStatus} from "../../../Apis/ResourcesApi";
import LinkCircleIcon from "../../../Assets/Icons/V2/LinkCircleIcon";
import PreviewCircleIcon from "../../../Assets/Icons/V2/PreviewCircleIcon";
import DownloadCircleIcon from "../../../Assets/Icons/V2/DownloadCircleIcon";
import {saveAs} from "file-saver";
import {ResourceAgreementDto} from "../../../Apis/Models/ResourceAgreementDto";
import {trackClick} from "../../../Utils/GAUtils";

const ResourcesPage = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const agreement = useSelectedAgreement();

    const { data: resources } = useResources(agreement);
    const { mutateAsync: mutateStatus } = useSaveStatus();

    const openLink = (resource: ResourceAgreementDto) => {
        window.open(resource.resourceType.link, "_blank")

        trackClick('resource_link_clicked', {resourceId: resource.resource.id, resourceName: resource.resourceType.title})
    }

    const openPreview = (resource: ResourceAgreementDto) => {
        window.open(resource.resourceType.preview?.url ?? resource.resource.preview?.url, "_blank")

        trackClick('resource_preview_clicked', {resourceId: resource.resource.id, resourceName: resource.resourceType.title})
    }

    const downloadFile = async (resource: ResourceAgreementDto) => {
        const response = await fetch(resource.resource.resourceFile.url);

        const blob = await response.blob();

        saveAs(blob, resource.resource.resourceFile.fileName);

        trackClick('resource_downloaded', {resourceId: resource.resource.id, resourceName: resource.resourceType.title})
    }

    const changeStatus = async (resource: ResourceAgreementDto) => {
        await mutateStatus({ resourceId: resource.resource.id, agreementId: agreement!.id })

        trackClick('resource_checked', {resourceId: resource.resource.id, resourceName: resource.resourceType.title})
    }

    return (
        <>
            <Box noPadding>
                <img src="/Images/Resources/resourcesbanner.png" alt="Resources" width={"100%"} height={"auto"}/>

                <div className={styles.content}>
                    <Gap size={"large"} direction={"vertical"} className={"w-100"}>

                        <Gap size={"small"} direction={"vertical"}>
                            <StyledText type={"Lead"} weight={"Bold"}
                                        color={"secondary"}>{t('resources.page.header')}</StyledText>

                            <div style={{maxWidth: 635}}>
                                <StyledText type={"Small"}
                                            color={"secondary"}>{t('resources.page.description')}</StyledText>
                            </div>

                        </Gap>

                        <AgreementSelector requireSelect position={"Under Left"}/>

                        {!!agreement && !!resources && (
                            <>
                                <FlexRow justify={"space-between"}>
                                    <StyledText type={"Body"}
                                                weight={"Bold"}>{resources.filter(x => !!x.checkedAt).length} {t('outOf')} {resources.length}</StyledText>

                                    <div className={styles.progressbar}>
                                        <div
                                            style={{width: `${resources.filter(x => x.checkedAt).length / resources.length * 100}%`}}
                                            className={styles.progress}></div>
                                    </div>
                                </FlexRow>

                                <div className={"w-100"}>
                                    {resources.map(resource => (
                                        <div className={styles.resourceItem} key={resource.resource.id}>
                                            <div>
                                                <img src={resource.resourceType.icon.url} alt={resource.resourceType.title} />
                                            </div>
                                            <div className={"flex align-center justify-center"}>
                                                <div className={"clickable"} onClick={() => changeStatus(resource)}>
                                                    {!!resource.checkedAt ? (
                                                        <div>
                                                            <svg width="35" height="33" viewBox="0 0 35 33" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M33.6899 16.5C33.6899 25.3332 26.4769 32.5 17.5716 32.5C8.6664 32.5 1.45337 25.3332 1.45337 16.5C1.45337 7.6668 8.6664 0.5 17.5716 0.5C26.4769 0.5 33.6899 7.6668 33.6899 16.5Z"
                                                                    fill="#5DC389" stroke="#5DC389"/>
                                                                <path
                                                                    d="M27.1399 11.308L14.632 24L8.00366 17.2738L10.2802 14.9692L14.4011 19.1496C14.4629 19.212 14.5465 19.247 14.6337 19.247C14.7209 19.247 14.8045 19.212 14.8663 19.1496L24.8633 9L27.1399 11.308Z"
                                                                    fill="white"/>
                                                            </svg>
                                                        </div>
                                                    ) : (
                                                        <svg width="35" height="33" viewBox="0 0 35 33" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M33.6899 16.5C33.6899 25.3332 26.4769 32.5 17.5716 32.5C8.6664 32.5 1.45337 25.3332 1.45337 16.5C1.45337 7.6668 8.6664 0.5 17.5716 0.5C26.4769 0.5 33.6899 7.6668 33.6899 16.5Z"
                                                                stroke="#E0E0E0"/>
                                                        </svg>
                                                    )}
                                                </div>
                                            </div>
                                            <Gap size={"small"} direction={"vertical"} className={"w-100"}>
                                                <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{resource.resourceType.title}</StyledText>
                                                <StyledText type={"Small"} color={"secondary"}>{resource.resourceType.description}</StyledText>
                                            </Gap>
                                            <Gap size={"medium"}>
                                                {resource.resourceType.link && (
                                                    <div onClick={() => openLink(resource)}>
                                                       <LinkCircleIcon />
                                                    </div>
                                                )}
                                                {(resource.resourceType.preview || resource.resource.preview) && (
                                                    <a href={resource.resourceType.preview?.url ?? resource.resource.preview?.url} target={"_blank"} rel={"noreferrer"}>
                                                        <div onClick={() => openPreview(resource)}>
                                                            <PreviewCircleIcon />
                                                        </div>
                                                    </a>
                                                )}
                                                {(resource.resource.resourceFile) && (
                                                    <div className={"clickable"} onClick={() => downloadFile(resource)}>
                                                        <DownloadCircleIcon />
                                                    </div>
                                                )}
                                            </Gap>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </Gap>
                </div>
            </Box>

            <Grid gridTemplateColumns={"1fr 1fr"} columnGap={20}>
                <CalendlyBooker/>
                <Box type={"info"} size={"small"} style={{width: '100%'}}>
                    <FlexRow className={"h-100"} align={"center"} justify={"space-between"}>
                        <FlexRow direction={"column"} align={"start"}>
                            <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                                {t('findYourContractHeader')}
                            </StyledText>
                            <StyledText type={"Caption"}>
                                {t('findYourContractBody')}
                            </StyledText>
                        </FlexRow>
                        <FlexRow justify={"end"}>
                            <Button text={t('myContract')} color={"secondary"} outline onClick={() => navigate("/dashboard/acceptedTerms?agreementId=" + agreement?.id)} />
                        </FlexRow>
                    </FlexRow>
                </Box>
            </Grid>
        </>
    )

}

export default ResourcesPage
