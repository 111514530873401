import DefaultPageLayout from "../../../../Layouts/DefaultPageLayout";
import Gap from "../../../../Components/Gap";
import {StyledText} from "../../../../Components/Text";
import Button from "../../../../Components/Button";
import styles from "./OnboardingPage.module.scss";
import {Trans, useTranslation} from "react-i18next";
import Box from "../../../../Components/Box";
import {useParams} from "react-router-dom";
import {useAgreement, useAgreementEstimate} from "../../../../Apis/AgreementApi";
import FlexRow from "../../../../Components/FlexRow";
import Separator from "Components/Separator";
import Grid from "Components/Grid";
import CircledSolarPanel from "../../../../Assets/Icons/V2/CircledSolarPanel";
import CircledLeaf from "../../../../Assets/Icons/V2/CircledLeaf";
import CircledDollar from "../../../../Assets/Icons/V2/CircledDollar";
import {formatNumber} from "../../../../Utils/NumberUtils";
import moment from "moment";
import CheckmarkCircle from "../../../../Assets/Icons/CheckmarkCircle";
import WarningTriangle from "../../../../Assets/Icons/V2/WarningTriangle";
import LinkIcon from "../../../../Assets/Icons/V2/LinkIcon";
import {useRemoveAuthorization} from "../../../../Apis/EloverblikAuthorizationApi";
import CalendlyBooker from "../../../../Features/Onboarding/Components/CalendlyBooker";
import CompanyDetailsModal from "../../../../Features/Onboarding/Components/CompanyDetailsModal";
import AgreementStartDateModal from "../../../../Features/Onboarding/Components/AgreementStartDateModal";
import AgreementCustomUsageModal from "../../../../Features/Onboarding/Components/AgreementCustomUsageModal";
import AgreementAddAuthorizationModal from "../../../../Features/Onboarding/Components/AgreementAddAuthorizationModal";
import AuthorizationIcon from "../../../../Assets/Icons/V2/AuthorizationIcon";
import useToast from "../../../../Hooks/useToast";
import React from "react";
import AgreementReviewModal from "../../../../Features/Onboarding/Components/AgreementReviewModal";
import The0MissionIcon from "../../../../Assets/Icons/The0MissionIcon";
import * as Radix from "@radix-ui/react-tooltip";

const OnboardingPage = () => {

    const { t } = useTranslation();

    const { agreementId } = useParams<{ agreementId: string }>();
    const { data: agreement, refetch } = useAgreement(agreementId!);
    const { data: agreementEstimate, refetch: refetchEstimate } = useAgreementEstimate(agreementId!);
    const { mutateAsync: removeAuthorizationMutation } = useRemoveAuthorization();

    const { showSuccessToast } = useToast();

    const removeAuthorization = async (id: string) => {
        await removeAuthorizationMutation(id);

        refetch();
        refetchEstimate();

        showSuccessToast(t('onboarding.authorizationRemoved'));

    }

    const getCustomUsageButtonState = () => {
        if (!agreement) {
            return null;
        }

        if (agreement.agreementType === 'Match') {
            return { outline: true };
        }

        if (agreement.customAgreementUsages.length > 0 && agreement.customAgreementUsages[0]?.yearlyUsage > 0) {
            return { outline: true }
        }

        return null
    }

    const getMatchUsageButtonState = () => {
        if (!agreement) {
            return null;
        }

        if (getCustomUsageButtonState() == null) {
            return { outline: true };
        }

        if (agreement.agreementType === 'Custom') {
            return null;
        }

        if (agreement.eloverblikAuthorizations.length > 0 && agreement.eloverblikAuthorizations.filter(x => x.status === 'Active').length > 0) {
            return { outline: true }
        }

        return null
    }

    const getAgreementReviewButtonState = () => {
        if (!agreement) {
            return null;
        }

        if (agreement.agreementType === 'Custom' || agreement.agreementType === 'MatchAndCustom') {
            if (agreement.customAgreementUsages.length === 0 || agreement.customAgreementUsages[0].yearlyUsage === 0) {
                return { outline: true, disabled: true, message: t('onboarding.agreementReview.customUsageMissing') };
            }
        }

        if (agreement.agreementType === 'Match' || agreement.agreementType === 'MatchAndCustom') {
            if (agreement.eloverblikAuthorizations.length === 0 || agreement.eloverblikAuthorizations.filter(x => x.status !== 'Active').length > 0) {
                return { outline: true, disabled: true, message: t('onboarding.agreementReview.eloverblikAuthorizationMissing') };
            }
        }

        if (!agreementEstimate) {
            return { outline: true, disabled: true };
        }

        return false;
    }

    if (!agreement) {
        return (
            <DefaultPageLayout contentMaxWidth={1440}>
                <FlexRow justify={"center"}>
                    <The0MissionIcon spin width={100} height={100} />
                </FlexRow>
            </DefaultPageLayout>
        )
    }

    return (
        <DefaultPageLayout contentMaxWidth={1440}>
            <div className={styles.content}>
                <FlexRow className={"w-100"}>
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>{t('onboarding.agreementOverview')}</StyledText>
                </FlexRow>
                <Box style={{maxWidth: 1440, width: '100%'}}>
                    {!!agreement && (
                        <Gap size={"medium"} direction={"vertical"}>
                            <FlexRow justify={"space-between"}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{agreement.companyName}</StyledText>
                                    <StyledText type={"Caption"}>CVR: {agreement.vatNumber}</StyledText>
                                    <StyledText type={"Caption"}>{agreement.invoiceEmail}</StyledText>
                                </FlexRow>

                                <CompanyDetailsModal agreement={agreement} trigger={(
                                    <Button outline text={t('editCompanyDetails')} />
                                )} />

                            </FlexRow>

                            <Separator />

                            <FlexRow justify={"space-between"}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('onboarding.startDate.startDate')}</StyledText>
                                    <StyledText type={"Caption"}>
                                        <Trans i18nKey={'onboarding.startDate.startDateDescription'} values={{date: moment(agreement.expectedStartDate).format("DD-MM-YYYY")}} />
                                    </StyledText>
                                </FlexRow>

                                <AgreementStartDateModal agreement={agreement} trigger={(
                                    <Button outline text={t('editAgreementStartDate')} />
                                )} />
                            </FlexRow>

                            {(agreement.agreementType === 'Custom' || agreement.agreementType === 'MatchAndCustom') && (
                                <>
                                    <Separator />

                                    <FlexRow justify={"space-between"}>
                                        <FlexRow direction={"column"} align={"start"}>
                                            <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('onbodarding.customUsage.title')}</StyledText>
                                            {agreement.customAgreementUsages.length > 0 ? (
                                                <StyledText type={"Caption"}>
                                                    <Trans i18nKey={"onbodarding.customUsage.filledMessage"} values={{amount: agreement.customAgreementUsages[0].yearlyUsage, usageUnit: agreement.customAgreementUsages[0].usageUnit}} />
                                                </StyledText>
                                            ) : (
                                                <StyledText type={"Caption"}>{t('onbodarding.customUsage.unfilledMessage')}</StyledText>
                                            )}
                                        </FlexRow>

                                        <AgreementCustomUsageModal agreement={agreement} trigger={(
                                            <Button text={t('onbodarding.customUsage.edit')} {...getCustomUsageButtonState()} />
                                        )} />
                                    </FlexRow>
                                </>
                            )}

                            {(agreement.agreementType === 'Match' || agreement.agreementType === 'MatchAndCustom') && (
                                <>
                                    <Separator />

                                    <FlexRow justify={"space-between"}>
                                        <FlexRow direction={"column"} align={"start"}>
                                            <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('onbodarding.matchUsage.title')}</StyledText>
                                            <StyledText type={"Caption"}>{t('onbodarding.matchUsage.filledMessage')}</StyledText>
                                        </FlexRow>

                                        <AgreementAddAuthorizationModal
                                            trigger={(
                                                <Button text={t('onbodarding.matchUsage.edit')} style={agreement.eloverblikAuthorizations.length > 0 ? {display: 'none'} : {}} {...getMatchUsageButtonState()} />
                                            )}
                                            agreement={agreement}
                                        />
                                    </FlexRow>

                                    {agreement.eloverblikAuthorizations.length > 0 && (
                                        <>
                                            <Separator />

                                            <FlexRow justify={"space-between"}>
                                                <FlexRow direction={"column"} align={"start"}>
                                                    <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('connectToMeter')}</StyledText>
                                                    <StyledText type={"Caption"}>
                                                        <Trans i18nKey={"onboarding.match.connectDescription"} values={{count: agreement.eloverblikAuthorizations.filter(x => x.status === "Active").length}} />
                                                    </StyledText>
                                                </FlexRow>
                                            </FlexRow>

                                            {agreement.eloverblikAuthorizations.map((authorization, index) => (
                                                <React.Fragment key={authorization.id}>
                                                    <Grid style={{width: '100%'}} gap={40} gridTemplateColumns={"repeat(3, clamp(200px, 15%, 300px)) auto"}>
                                                        <Gap size={"small"} direction={"vertical"}>
                                                            <StyledText type={"Caption"} weight={"Bold"}>
                                                                {t('powerOfAttorneyFrom')}
                                                            </StyledText>

                                                            <StyledText type={"Caption"}>
                                                                {moment(authorization.validFrom).format('DD.MM.YYYY')}
                                                            </StyledText>
                                                        </Gap>

                                                        <Gap size={"small"} direction={"vertical"}>
                                                            <StyledText type={"Caption"} weight={"Bold"}>
                                                                {t('powerOfAttorneyTo')}
                                                            </StyledText>

                                                            <StyledText type={"Caption"}>
                                                                {moment(authorization.validTo).format('DD.MM.YYYY')}
                                                            </StyledText>
                                                        </Gap>

                                                        <Gap size={"small"} direction={"vertical"}>
                                                            <Gap size={"small"}>
                                                                <StyledText type={"Caption"} weight={"Bold"}>
                                                                    {t('status')}
                                                                </StyledText>
                                                                {authorization.status === 'Active' && (
                                                                    <CheckmarkCircle fill={"#18A957"} />
                                                                )}
                                                                {(authorization.status === 'Pending' || authorization.status === 'Expired') && (
                                                                    <WarningTriangle />
                                                                )}
                                                            </Gap>

                                                            <StyledText type={"Caption"}>
                                                                {authorization.status === 'Active' && (
                                                                    t('authorizationStatus.active')
                                                                )}
                                                                {authorization.status === 'Pending' && (
                                                                    t('authorizationStatus.pending')
                                                                )}
                                                                {authorization.status === 'Expired' && (
                                                                    t('authorizationStatus.expired')
                                                                )}
                                                            </StyledText>
                                                        </Gap>

                                                        <FlexRow justify={"end"}>
                                                            <div onClick={() => removeAuthorization(authorization.id)}>
                                                                <Gap size={"small"}>
                                                                    <LinkIcon />
                                                                    <StyledText type={'Button'} color={"primary"}>{t('onboarding.removeAccess')}</StyledText>
                                                                </Gap>
                                                            </div>
                                                        </FlexRow>
                                                    </Grid>

                                                    {index < agreement.eloverblikAuthorizations.length - 1 && (
                                                        <Separator />
                                                    )}

                                                </React.Fragment>
                                            ))}

                                            {agreement.eloverblikAuthorizations.length > 0 && (
                                                <AgreementAddAuthorizationModal
                                                    trigger={(
                                                        <div className={"flex align-center"}>
                                                            <AuthorizationIcon />
                                                            <StyledText tag={"span"} style={{paddingLeft: 8}} type={"Caption"} color={"primary"} weight={"Bold"}>
                                                                {t('onboarding.addAnotherAuthorization')}
                                                            </StyledText>
                                                        </div>
                                                    )}
                                                    agreement={agreement}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                            <Separator />

                            <FlexRow justify={"space-between"}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('agreementOverview')}</StyledText>
                                    {(agreementEstimate?.totalPriceInCents ?? 0) > 0 ? (
                                        <StyledText type={"Caption"}>
                                            <Trans i18nKey={'onboarding.agreement.filled'} values={{amount: agreementEstimate!.estimatedKwh / 1000 }}
                                            />
                                        </StyledText>
                                    ) : (
                                        <StyledText type={"Caption"}>{t('onboarding.agreement.unfilled')}</StyledText>
                                    )}
                                </FlexRow>

                                <AgreementReviewModal estimate={agreementEstimate} agreement={agreement} trigger={getAgreementReviewButtonState()?.hasOwnProperty('disabled') ? (
                                     <Radix.Provider>
                                        <Radix.Root>
                                            <Radix.Trigger asChild>
                                                <div>
                                                    <Button text={t('reviewAgreement')} {...getAgreementReviewButtonState()} />
                                                </div>
                                            </Radix.Trigger>
                                            <Radix.Portal>
                                                <Radix.Content className={styles.tooltip}>
                                                    {`${getAgreementReviewButtonState()?.hasOwnProperty('message') ? getAgreementReviewButtonState()!['message'] : ''}`}
                                                    <Radix.Arrow width={20} height={10} />
                                                </Radix.Content>
                                            </Radix.Portal>
                                        </Radix.Root>
                                    </Radix.Provider>
                                ) : (
                                    <Button text={t('reviewAgreement')} {...getAgreementReviewButtonState()} />
                                )} />

                            </FlexRow>

                            <div />

                            {!agreementEstimate ? (
                                <FlexRow align={"center"} justify={"center"}>
                                    <The0MissionIcon spin />
                                </FlexRow>
                            ) : (
                                <Grid gridTemplateColumns={"1fr 1fr 1fr"} style={{width: '100%'}}>
                                    <FlexRow justify={"center"} align={"start"} style={{gap: 8}}>
                                        <div>
                                            <CircledSolarPanel />
                                        </div>
                                        <div className={"flex column"}>
                                            <StyledText type={"Body"} weight={"Bold"}>
                                                {t('solarpanels')}:&nbsp;
                                                {agreementEstimate?.solarPanelMeters > 0 && (
                                                    <>
                                                        {agreementEstimate?.solarPanelMeters > 1000 ? formatNumber(agreementEstimate!.solarPanelMeters / 1000) : formatNumber(agreementEstimate?.solarPanelMeters)} {(agreementEstimate?.solarPanelMeters ?? 0) > 1000 ? 'km' : 'm'}
                                                    </>
                                                )}
                                            </StyledText>
                                            <StyledText type={"Small"} style={{maxWidth: 250}}>{agreementEstimate?.solarPanelMeters > 0 ? t('onboarding.details.solarPanelDescription') : t('onboarding.detauls.solarPanelEmpty')}</StyledText>
                                        </div>
                                    </FlexRow>

                                    <FlexRow justify={"center"} align={"start"} style={{gap: 8}}>
                                        <div>
                                            <CircledLeaf />
                                        </div>
                                        <div className={"flex column"}>
                                            <StyledText type={"Body"} weight={"Bold"}>{t('yearlyReduction')}&nbsp;
                                                {agreementEstimate?.amountOfCO2InGrams > 0 && (
                                                    <>
                                                        {formatNumber((agreementEstimate?.amountOfCO2InGrams ?? 0) / 1000 / 1000)} ton
                                                    </>
                                                )}
                                            </StyledText>
                                            <StyledText type={"Small"} style={{maxWidth: 250}}>{agreementEstimate?.solarPanelMeters > 0 ? t('onboarding.details.yearlyReductionDescription') : t('onboarding.details.yearlyReductionDescriptionEmpty')}</StyledText>
                                        </div>
                                    </FlexRow>

                                    <FlexRow justify={"center"} align={"start"} style={{gap: 8}}>
                                        <div>
                                            <CircledDollar />
                                        </div>
                                        <div className={"flex column"}>
                                            <StyledText type={"Body"} weight={"Bold"}>{t('yearlyCost')}&nbsp;
                                                {agreementEstimate?.totalPriceInCents > 0 && (
                                                    <>
                                                        DKK {formatNumber((agreementEstimate?.totalPriceInCents ?? 0) / 100)}
                                                    </>
                                                )}
                                            </StyledText>
                                            <StyledText type={"Small"} style={{maxWidth: 250}}>{agreementEstimate?.solarPanelMeters > 0 ? t('onboarding.details.yearlyCostDescription') : t('onboarding.details.yearlyCostDescriptionEmpty')}</StyledText>
                                        </div>
                                    </FlexRow>
                                </Grid>
                            )}
                        </Gap>
                    )}
                </Box>

                <CalendlyBooker />
            </div>
        </DefaultPageLayout>
    )

}

export default OnboardingPage
