import Space from "../../../Components/Space";
import Textarea from "../../../Components/Textarea";
import Checkbox from "../../../Components/Checkbox";
import FlexRow from "../../../Components/FlexRow";
import Button from "../../../Components/Button";
import Modal from "../../../Components/Modal";
import { useTranslation } from "react-i18next";
import {useForm} from "react-hook-form";
import {CreateTermsModel} from "../../../Apis/Models/CreateTermsModel";
import {useState} from "react";
import {AgreementTermsModel} from "../../../Apis/Models/AgreementTermsModel";
import {ControlledInput} from "../../../Components/Input";
import InputLabel from "../../../Components/InputLabel";

type Props = {
    onSave: (data: any) => void;
    onCancel: () => void;
    visible: boolean;
    isCustomTerms?: boolean;
    terms: AgreementTermsModel[];
}
const CreateTermsModal = (props: Props) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false)
    const { getValues, setValue, register, control, handleSubmit, reset, formState: { errors } } = useForm<CreateTermsModel>();

    const onSubmit = async (data: any) => {
        setLoading(true)

        await props.onSave(data);

        reset();
        props.onCancel();

        setLoading(false)
    }

    const loadTermsText = () => {
        let termsType = getValues('agreementType');

        if (!termsType) {
            return;
        }

        const defaultTerms = props.terms.find(x => x.agreementType === termsType && x.defaultTerms);

        if (defaultTerms) {
            setValue('text', defaultTerms.text)
        }
    }

    return (
        <Modal title={t('terms:createNewTerms')} visible={props.visible} onCancel={props.onCancel}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Space>

                    <div className={"w-100"}>
                        <InputLabel>
                            {t('terms:name')}
                        </InputLabel>
                        <ControlledInput name={"name"} control={control} rules={{required: true}}/>
                    </div>

                    <div className={"w-100"}>
                        <InputLabel>
                            {t('terms:terms')}
                        </InputLabel>
                        <Textarea name={"text"} control={control} label={t('terms:terms')} rules={{required: true}}
                                  errors={errors.text}/>
                    </div>

                    {props.isCustomTerms && (
                        <div className={"w-100"}>
                            <Button onClick={loadTermsText} text={t('prefillTermsWithDefaultTermsByType')} />
                        </div>
                    )}

                    <div className={"w-100"}>
                        <InputLabel>
                            {t('agreementType')}
                        </InputLabel>
                        <select className={"select"} {...register('agreementType')}>
                            <option value={"Custom"}>{t('agreement:custom')}</option>
                            <option value={"Match"}>{t('agreement:match')}</option>
                            <option value={"MatchAndCustom"}>{t('agreement:matchAndCustom')}</option>
                        </select>
                    </div>

                    <div className={"w-100"}>
                        <InputLabel>
                            {t('terms:unitSalesPricePrMwh')}
                        </InputLabel>
                        <ControlledInput name={"unitSalesPrice"} type={"number"} control={control}
                               placeholder={t('terms:unitSalesPricePrMwh')} rules={{required: true}}
                               />
                    </div>

                    {props.isCustomTerms ? (
                        <div className={"w-100"}>
                            <InputLabel>
                                {t('terms:useTermsFrom')}
                            </InputLabel>
                            <ControlledInput name={"from"} type={"date"} control={control} placeholder={t('useTermsFrom')} rules={{required: true}}/>
                        </div>
                    ) : (
                        <Checkbox name={"defaultTerms"} control={control} label={t('terms:defaultTerms')} errors={errors.defaultTerms}/>
                    )}


                    <FlexRow justify={"end"}>
                        <Button disabled={loading} type={"submit"} state={loading ? 'Loading' : 'None'} text={t('terms:createNewTerms')}/>
                    </FlexRow>
                </Space>
            </form>
        </Modal>
    )

}

export default CreateTermsModal
