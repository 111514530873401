import styles from './OnboardingBoxContent.module.scss';
import Separator from "../../../Components/Separator";
import React from "react";


type Props = {
    content: any;
    buttonText?: any;
    buttons?: any;
    separator?: boolean;
}
const OnboardingBoxContent = ({content, buttonText, buttons, separator}: Props) => {

    return (
        <div className={styles.container}>
            <div>
                {content}
            </div>

            {separator && (
                <Separator />
            )}

            {(!!buttons || !buttonText) && (
                <div>
                    {buttons}
                </div>
            )}
        </div>
    )

}

export default OnboardingBoxContent
