import {request} from "./BaseApi";
import { useQuery } from "react-query";
import {UserContext} from "Apis/Models/UserContext";

export const USER_CONTEXT_CACHE_KEY = 'USER_CONTEXT_CACHE_KEY';
export const useUserContext = (enabled: boolean) => {

    return useQuery<UserContext>(USER_CONTEXT_CACHE_KEY, async () => {
        return await request<UserContext>({
            url: `/usercontext`,
            method: 'GET',
    })}, {
        enabled,
        refetchOnWindowFocus: false
    });
}
