import { useTranslation } from "react-i18next";
import {StyledText} from "../../../Components/Text";

type Props = {
    totalSteps: number;
    currentStep: number;
}
const Steps = (props: Props) => {

    const { t } = useTranslation();
    
    return (
        <StyledText type={"Small"} weight={"Bold"} color={"primary"}>
            {t('step')} {props.currentStep} {t('of')} {props.totalSteps}
        </StyledText>
    )

}

export default Steps
